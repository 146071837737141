import {React, useState} from 'react';
import { useRef } from 'react';
import html2pdf from 'html2pdf.js';
// import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas';
import './index.css';

function RepaymentSchedule( Borrower){

    // const repaymentScheduleColumns = ['Period', 'No. of month', 'Outstanding Cost', 'Margin Contribution', 'Sanctioned loan amount', 'Principal amount', 'Interest', 'Total EMI', 'Per month EMI'];
    const repaymentScheduleColumns = ['Period', 'Outstanding Cost', 'Margin Contribution', 'Sanctioned loan amount', 'Principal amount', 'Interest', 'Per month EMI'];

    const [repaymentVariables, setRepaymentVariables] = useState({});
    const [periodAndMonthArr, setPeriodAndMonthArr] = useState([]);

    const [IsShowButton, SetIsShowButton] = useState(true);
    // const [isShowPrintButton, SetIsShowPrintButton] = useState(true);
    const [isShowMessage, SetIsShowMessage] = useState(false);
    const [isShowDenialMessage, SetIsShowDenialMessage] = useState(false);
    const [buttonFlag, SetButtonFlag] = useState(false);

    const contentRef = useRef();
    const generatePDF = () => {
      const input = contentRef.current; // Capture the DOM element
  
      const options = {
        margin: 0.2,
        filename: 'RepaymentSchedule.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    };
    
    html2pdf().set(options).from(input).save();
      // Convert the content to canvas using html2canvas
      // html2canvas(input).then((canvas) => {
      //   const imgData = canvas.toDataURL('image/png');
      //   const pdf = new jsPDF('p', 'mm', 'a4');
      //   const imgWidth = 210; // Width of the A4 page in mm
      //   const pageHeight = 297; // Height of A4 page in mm
      //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
      //   let heightLeft = imgHeight;
      //   let position = 0;
  
      //   // Add the image to the PDF
      //   pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      //   heightLeft -= pageHeight;
  
      //   // If the content exceeds one page, add additional pages
      //   while (heightLeft > 0) {
      //     position = heightLeft - imgHeight;
      //     pdf.addPage();
      //     pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      //     heightLeft -= pageHeight;
      //   }
  
      //   // Save the PDF file
      //   pdf.save('RepaymentSchedule.pdf');
      // });
    };

    async function getDetails(){

      try {
        if(Borrower.BorrowerId !== -1){
          let reqBody = {};
          reqBody.BorrowerId = Borrower.BorrowerId;
          const response = await fetch("https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/getPrincipalAndInterestDetail",{method:"POST",
          mode:"cors",
          headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify(reqBody)
      }).then((response) =>
     { 
      console.log(response);
      if(response.ok){
        console.log("Success");
      }else{
        console.log("Failure");
      }
      return response.json();
     }
    ).then((data) => {
      console.log(data.body);
      if(data.Message === "Success"){
        SetIsShowButton(false);
        console.log("Data: Success");
        setRepaymentVariables(data.sourceOfFunds);
        setPeriodAndMonthArr(data.PeriodAndMonth);
      }
      else if(data.Message === "Data Not Found"){
        SetIsShowDenialMessage(true);
      }
      return data;
    });
      console.log(response);
  }else{
    SetIsShowMessage(true);
  }
      } catch (error) {
        alert(error);
      }
      
    }
// getDetails();
function closeMessageModal(){
  SetIsShowMessage(false);
}

function closeDenialMessageModal(){
  SetIsShowDenialMessage(false);
}

// const print = () => {
//   window.print();
// }

const hideButtons = () => {
  SetIsShowButton(!buttonFlag);
  SetButtonFlag(!buttonFlag);
}

// const handlePrint = () => {
//     // Capture the content you want to print
//     // const content = contentRef.current;

//     // SetIsShowPrintButton(false);
//     // Open print dialog
//     window.print();
//   };
    return (
        <>
         {/* {isShowPrintButton && */}
         {
          IsShowButton &&
          <>
        {/* <button onClick={handlePrint}>Print</button> */}
        
        <button onClick={getDetails}>GetRepaymentSchedule</button>
        <br/>
        </>
         }
        <button onClick={hideButtons}>...</button>
        <br/>
        <button onClick={generatePDF}>DownloadAsPdf</button>
        
        <div className='print-section'  ref={contentRef}>
        <h3>Repayment Schedule (TL) (Monthly)</h3>
        
        <div>
        <span>Bank loan</span>&nbsp;&nbsp;
        <span>{repaymentVariables.bankLoan} /-</span>
        &nbsp; &nbsp; &nbsp;&nbsp;
        <span>Interest rate:</span>&nbsp;&nbsp;<span> {repaymentVariables.interestRateByBank}% p.a.</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span>PERIOD: </span>&nbsp;&nbsp;&nbsp;<span>{repaymentVariables.period} months</span>
        </div>
        
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
                <tr>
            {    repaymentScheduleColumns.map((repaymentSchedule, index) => (
                
                      <th key={index}>{repaymentSchedule}</th>
                              
        
                  ))}
                </tr>
            </thead>
            {/* <t key='About Project'>About Project&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Amount(Rs.)</li> */}
            <tbody>
                {/* // showProjectList() */}
                {/* // projects.map((project) => (<li key={project.aboutProject}>{project.aboutProject}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{project.AmountOfProject}</li>)) */}
                {
                    periodAndMonthArr.map((element, index) => (
                        <tr key={index}>
                            <th style={{width: "100px", backgroundColor: 'green'}}>{element.Period}</th>
                            {/* <th style={{width: "100px", backgroundColor: 'yellow'}}>{element.NoOfMonth}</th> */}
                            <th style={{width: "100px", backgroundColor: 'yellow'}}>{(element.TotalOutstandingValue === undefined) ? "Total" : (parseFloat(element.TotalOutstandingValue)).toFixed(2)}</th>
                            <th style={{width: "100px", backgroundColor: 'white'}}>{element.MarginContribution}</th>
                            <th style={{width: "100px", backgroundColor: 'white'}}>{element.SanctionedLoanAmount}</th>
                            <th style={{width: "100px", backgroundColor: 'yellow'}}>{(element.PrincipalAmount === undefined) ? (parseFloat(element.principalAmount)).toFixed(2) : (parseFloat(element.PrincipalAmount)).toFixed(2)}</th>
                            <th style={{width: "100px", backgroundColor: 'yellow'}}>{(element.Interest === undefined) ? (parseFloat(element.interest)).toFixed(2) : (parseFloat(element.Interest)).toFixed(2)}</th>
                            {/* <th style={{width: "100px", backgroundColor: 'yellow'}}>{(element.TotalEMI).toFixed(2)}</th> */}
                            <th style={{width: "100px", backgroundColor: 'yellow'}}>{(element.PerMonthEMI === undefined) ? '' : (parseFloat(element.PerMonthEMI)).toFixed(2)}</th>
                        </tr>
                    ))
                }
                <tr>
                    <th style={{ border: '1px solid black'}}>{repaymentVariables.period}</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th style={{ border: '1px solid black'}}>{(periodAndMonthArr.reduce((element, curr) => ((curr.PrincipalAmount === undefined) ? 0.0 : parseFloat(curr.PrincipalAmount)) + element, 0)).toFixed(2)}</th>
                    <th style={{ border: '1px solid black'}}>{(periodAndMonthArr.reduce((element, curr) => ((curr.Interest === undefined) ? 0.0 : parseFloat(curr.Interest)) + element, 0)).toFixed(2)}</th>
                    <th></th>
                </tr>
            
            
            </tbody>
            </table>
            </div>

            {isShowMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeMessageModal}>&times;</span>
                        
                            <h2 style={{ color: "green" }}>
                                Please select a borrower!..
                            </h2>

                            <center><button onClick={closeMessageModal} style={{backgroundColor: 'green', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}

{isShowDenialMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeDenialMessageModal}>&times;</span>
                        
                            <h2 style={{ color: "red" }}>
                            Project Data is Missing!..
                            </h2>
                    </div>
                </div>
            )}

        </>
    );
}

export default RepaymentSchedule;