import {React, useState, useRef} from "react";
import { FcApproval } from "react-icons/fc";
import { ImCross } from "react-icons/im";
import html2pdf from 'html2pdf.js';
// import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas';

function FixedAssets(Borrower){

    let rateOfDepreciation = [
        0, 5, 10, 15, 20, 25, 30, 40
    ];

    let [rateofDepriciation, SetRateOfDep] = useState([]);
    const [periodOfLoan, setPeriodOfLoan] = useState(0);
    const [FAssetsTable, setFAssetsTable] = useState([]);
    let [fixedAssets, SetFixedAssets] = useState([]);
    const [depreciationAllowable, SetDepreciationAllowable] = useState([]);
    const [isShowSelectBorrowerMessage, SetIsShowSelectBorrowerMessage] = useState(false);
    const [isShowNoDataMessage, SetIsShowNoDataMessage] = useState(false);
    const [isShowDataMessage, SetIsShowDataMessage] = useState(false);

    const [isProjectsSaved, setIsProjectsSaved] = useState(false);
    const [isProjectItemsSavedSuccess, setIsProjectItemsSavedSuccess] = useState(false);
    const [buttonFlag, SetButtonFlag] = useState(false);
    const [IsShowButton, SetIsShowButton] = useState(true);
    const [emiPeriodDetails, SetEmiPeriodDetails] = useState({});
    let emiStartMonth = "";
    const [depreciationApplicable, SetDepreciationApplicable] = useState(false);

    // const contentRef = useRef(null);

    const fixedAssetsColumns = ['Description of Assets', 'Rate of Depreciation', 'Opening W. D. V.',
     'Additions (Note-A)', 'Deductions (Note-B)', 'Total', 'Depreciation Allowable', 'Closing WDV']
     const a2h = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];

     const depreciationMonths = ['october', 'november', 'december', 'january', 'february', 'march'];
     
     const contentRef = useRef();
     const generatePDF = () => {
       const input = contentRef.current; // Capture the DOM element
     
       const options = {
        margin: 0.2,
        filename: 'FixedAssets.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    };
    
    html2pdf().set(options).from(input).save();
       // Convert the content to canvas using html2canvas
      //  html2canvas(input).then((canvas) => {
      //    const imgData = canvas.toDataURL('image/png');
      //    const pdf = new jsPDF('p', 'mm', 'a4');
      //    const imgWidth = 210; // Width of the A4 page in mm
      //    const pageHeight = 297; // Height of A4 page in mm
      //    const imgHeight = (canvas.height * imgWidth) / canvas.width;
      //    let heightLeft = imgHeight;
      //    let position = 0;
     
      //    // Add the image to the PDF
      //    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      //    heightLeft -= pageHeight;
     
      //    // If the content exceeds one page, add additional pages
      //    while (heightLeft > 0) {
      //      position = heightLeft - imgHeight;
      //      pdf.addPage();
      //      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      //      heightLeft -= pageHeight;
      //    }
     
      //    // Save the PDF file
      //    pdf.save('FixedAssets.pdf');
      //  });
     };
    async function assetsDetail(){

      try {
        if(Borrower.BorrowerId !== -1){
          let reqBody = {};
          reqBody.BorrowerId = Borrower.BorrowerId;
          const response = await fetch("https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/getFixedAssets",{method:"POST",
          mode:"cors",
          headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify(reqBody)
      }).then
      ((response) =>
      { 
       console.log(response);
       if(response.ok){
         console.log("Success");
       }else{
         console.log("Failure");
       }
       return response.json();
      }
     ).then((data) => {
       console.log(data.body);
       if(data.Message === "Success"){
         console.log("Data: Success");
       }
       SetFixedAssets(data["Fixed Assets"]);
       setPeriodOfLoan(data.periodOfLoan);
       const fassetTable = [];
       for(let i = 0; i < periodOfLoan + 1; i++){
  
          fassetTable.push(i);
          // setFAssetsTable(fassetTable);
          // console.log(FAssetsTable);
       }
       console.log(periodOfLoan);
       let fa = [...data["Fixed Assets"]];
       for(let i = 0; i < fa.length; i++){
        if(emiPeriodDetails.gstItc === 'No'){
        fa[i].total = parseFloat((parseFloat((fa[i].total).toFixed(2)) + parseFloat((fa[i].gstValue).toFixed(2))).toFixed(2));
        fa[i].additionsNote_A = parseFloat((parseFloat((fa[i].additionsNote_A).toFixed(2)) + parseFloat((fa[i].gstValue).toFixed(2))).toFixed(2));
        fa[i].wdv_Closing = parseFloat((parseFloat((fa[i].wdv_Closing).toFixed(2)) + parseFloat((fa[i].gstValue).toFixed(2))).toFixed(2));
        }
       }
       let FAssetsArray = fassetTable.map(element => (
          element = [...fa]
       ))
       setFAssetsTable(FAssetsArray);
        console.log(FAssetsArray);
       let rateOfDep = [...rateofDepriciation];
       let rOfDep = rateOfDep.map(element=>(
          element = 0
       ))
      //  if(FAssetsArray.length === 0){
      //   SetIsShowNoDataMessage(true);
      //  }
       console.log(rOfDep);
       SetRateOfDep(rOfDep);
       return data;
     });
  
     console.log(response);
    }else{
      SetIsShowSelectBorrowerMessage(true);
    }
      } catch (error) {
        alert(error);
      }
    } 


//    async function handleChangeOfDepreciation(e, index){
//     const {value} = e.target;
//     const updatedRates = [...rateofDepriciation];
//     updatedRates[index] = value;
//     // let rateOfDep = [...rateofDepriciation, e.target.value];
//     SetRateOfDep(updatedRates);

//     // Update depreciation values for the specific asset
//     const total = fixedAssets[index].total;
//     const deprValue = total * (value / 100);
//     const WDV_Closing = total - deprValue;

//     // Update the fixedAssets state
//     SetFixedAssets(prevAssets => {
//         const updatedAssets = [...prevAssets];
//         updatedAssets[index] = {
//             ...updatedAssets[index],
//             depreciationAllowable: deprValue,
//             wdv_Closing: WDV_Closing
//         };
//         // return updatedAssets;
//     });

//     // for(let i = 0; i < fixedAssets.length; i++){
//     //     let total = await fixedAssets[i].amountOfProject;
//     //     let deprValue = await total * (rateofDepriciation[i])/100;
//     //     let WDV_Closing = await total - deprValue;
//     //     // SetWDV_Closing(WDV_Closing);
//     //     fixedAssets[i].depreciationAllowable = deprValue;
//     //     fixedAssets[i].WDV_Closing = WDV_Closing;
//     // }
//     console.log(fixedAssets);

//     }

    // const handlePrint = () => {
    //     // Capture the content you want to print
    //     const content = contentRef.current;
    
    //     // Open print dialog
    //     window.print(content);
    //     console.log(rateofDepriciation);
    //   };

      function closeMessageModal(){
        SetIsShowSelectBorrowerMessage(false);
      }

      const saveDepreciationAllowable = async () => {
        try {
          if(Borrower.BorrowerId !== -1){
            if(FAssetsTable.length !== 0){
          let fassets = [...FAssetsTable[0]];
          console.log(fassets, "fassets");
          let lengthOfAssets = fassets.length;
          if(rateofDepriciation.length === lengthOfAssets){
              for(let i = 0; i < lengthOfAssets; i++){
                  if(rateofDepriciation[i] === undefined){
                      rateofDepriciation[i] = 0
                  }else{
                      rateofDepriciation[i] = parseFloat(rateofDepriciation[i]);
                  }
              }
          }else{
              //rateofDepriciation[i] = parseFloat(rateofDepriciation[i]);
              console.log(rateofDepriciation);
              return;
          }
          console.log(rateofDepriciation);
          let fAssets = [...FAssetsTable];
          if(rateofDepriciation.length === lengthOfAssets){
              let deprAllowable = [...depreciationAllowable];
              for(let i = 0; i < FAssetsTable.length; i++){
                  let depreciation = (fAssets[i].reduce((element,curr) => curr.depreciationAllowable + element,0)).toFixed(2);
                  deprAllowable[i] = parseFloat(depreciation);
              }
              console.log(deprAllowable);
              SetDepreciationAllowable(deprAllowable);
              console.log(depreciationAllowable);
  
  
          
  
          let reqBody = {};
          reqBody.BorrowerId = Borrower.BorrowerId;
          reqBody.deprValue = deprAllowable;
          const response = await fetch("https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/saveDepreciationValues",{method:"POST",
          mode:"cors",
          headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify(reqBody)
      }).then
      ((response) =>
      { 
       console.log(response);
       if(response.ok){
         console.log("Success");
       }else{
         console.log("Failure");
       }
       return response.json();
      }
     ).then((data) => {
      console.log(data.Message, data);
      setIsProjectsSaved(true);
      if(data.Message === 'Success'){
        setIsProjectItemsSavedSuccess(true);
      }
  });
  console.log(response);
  }
      }else{
        SetIsShowNoDataMessage(true);
      }    }else{
            SetIsShowSelectBorrowerMessage(true);
          }
        } catch (error) {
          alert(error);
        }
      }

      const getEmiDetails = async () => {
        try {
          if(Borrower.BorrowerId !== -1){
            let reqBody = {};
            reqBody.BorrowerId = Borrower.BorrowerId;
            const response = await fetch("https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/getEmiPeriodDetails",{method:"POST",
            mode:"cors",
            headers: {
              "Content-Type": "application/json",
              },
              body: JSON.stringify(reqBody)
        }).then
        ((response) =>
        { 
         console.log(response);
         if(response.ok){
           console.log("Success");
         }else{
           console.log("Failure");
         }
         return response.json();
        }
        ).then((data) => {
        console.log(data.Message, data);
        if(data.Message === 'Failure'){
          SetIsShowDataMessage(false);
        }else if(data.Message === 'Success'){
          SetIsShowDataMessage(true);
          let emiPeriodDetail = data.EmiPeriodDetails;
          SetEmiPeriodDetails(data.EmiPeriodDetails);
          emiStartMonth = emiPeriodDetail.emiStartMonth;
          emiStartMonth = emiStartMonth.toLowerCase();
          for(let i = 0; i < depreciationMonths.length; i++){
            if(depreciationMonths[i] === emiStartMonth){
              SetDepreciationApplicable(true);
              break;
            }
          }
        }
        });
        console.log(response);
          }else{
            SetIsShowSelectBorrowerMessage(true);
          }
        } catch (error) {
          alert(error);
        }
      }

const getDepreciationAllowable = async () => {

  if(Borrower.BorrowerId !== -1){
    let reqBody = {};
    reqBody.BorrowerId = Borrower.BorrowerId;
    const response = await fetch("https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/getDepreciationValues",{method:"POST",
    mode:"cors",
    headers: {
      "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody)
}).then
((response) =>
{ 
 console.log(response);
 if(response.ok){
   console.log("Success");
 }else{
   console.log("Failure");
 }
 return response.json();
}
).then((data) => {
console.log(data.Message, data);
if(data.Message === 'Failure'){
  SetIsShowNoDataMessage(true);
}else if(data.Message === 'Success'){
  SetIsShowDataMessage(true);
}
});
console.log(response);
  }else{
    SetIsShowSelectBorrowerMessage(true);
  }
}

function closeNoDataModal(){
  SetIsShowNoDataMessage(false);
}

function closeDataModal(){
  SetIsShowDataMessage(false);
}

const closeSaveModal = () => {
  setIsProjectsSaved(false);
}

const hideButtons = () => {
  SetIsShowButton(!buttonFlag);
  SetButtonFlag(!buttonFlag);
}

return(
    <>
    {IsShowButton && <>
      <button onClick={getEmiDetails}>1. GetEmiDetails</button>
    <button onClick={assetsDetail}>2. GetDetail</button>
    <button onClick={saveDepreciationAllowable}>3. SaveDepreciation</button>
    <button onClick={getDepreciationAllowable}>4. GetDepreciation</button>
    <button onClick={generatePDF}>5. DownloadAsPdf</button>
    </>}
    <button onClick={hideButtons}>...</button>
    {/* <button onClick={handleDownloadPDF}>DownloadAsPdf</button> */}
    <div ref={contentRef}>
        <h2><center><u>FIXED ASSETS</u></center></h2>

        <br/>
        <br/>
        {FAssetsTable.map((FAssetsElement, point) => ( 
        <table key = {point} style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
                <tr style={{ border: '1px solid black'}}>
            {    fixedAssetsColumns.map((fixedAssetsHead, index) => (
                
                      <th key={index} style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}>{fixedAssetsHead}</th>
                              
        
                  ))}
                </tr>
                <tr style={{ border: '1px solid black'}}>
            {    a2h.map((a2hHead, index) => (
                
                      <th key={index} style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}>{a2hHead}</th>
                              
        
                  ))}
                </tr>
            </thead>
            <tbody style={{textAlign:"center"}}>
                {
                    FAssetsElement.map((asset, index) => (
                        <tr key={index}>
                            <td style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}>{asset.aboutProject}</td>
                            <td style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}>
                             <select value={rateofDepriciation[index]} onChange={(e)=>{
                const {value} = e.target;
                const updatedRates = [...rateofDepriciation];
                updatedRates[index] = value;
                SetRateOfDep(updatedRates);

                // Update depreciation values for the specific asset
                console.log(FAssetsTable)
                let FixedAssetsTab = [...FAssetsTable];

                // let updatedAssets = [...FAssetsElement];
                for(let p = 0; p < FAssetsTable.length; p++){
                let fA = FixedAssetsTab[p];
                console.log(fA, index);
                let total = fA[index].total;
                let deprValue;
                if(p === 0 && depreciationApplicable){
                  deprValue = total * (value / 100)/2;
                }else{
                  deprValue = total * (value / 100);
                }
                
                const WDV_Closing = total - deprValue;

                FixedAssetsTab[p][index] = {
                    ...FixedAssetsTab[p][index],
                    depreciationAllowable: deprValue,
                    wdv_Closing: WDV_Closing
                }

                    // let FixedAssetsTable = [...FAssetsTable]
                    console.log(p, index);
                    if (p + 1 < FAssetsTable.length){    
                    FixedAssetsTab[p+1][index].wdv_Opening = FixedAssetsTab[p][index].wdv_Closing;
                    FixedAssetsTab[p+1][index].deductionsNote_B = 0;
                    FixedAssetsTab[p+1][index].additionsNote_A = 0;
                    FixedAssetsTab[p+1][index].total = FixedAssetsTab[p+1][index].wdv_Opening + FixedAssetsTab[p+1][index].additionsNote_A - FixedAssetsTab[p+1][index].deductionsNote_B;
                    FixedAssetsTab[p][index].wdv_Closing = FixedAssetsTab[p][index].total - FixedAssetsTab[p][index].depreciationAllowable;
                    setFAssetsTable(FixedAssetsTab);
                    }
            }
                // Update the fixedAssets state
                // setFAssetsTable(FixedAssetsTab);
                // console.log(FAssetsElement);
                // FAssetsElement = [...updatedAssets];
                // setFAssetsTable(updatedAssets);
                // SetFixedAssets(prevAssets => {
                //     const updatedAssets = [...prevAssets];
                //     updatedAssets[index] = {
                //         ...updatedAssets[index],
                //         depreciationAllowable: deprValue,
                //         wdv_Closing: WDV_Closing
                //     };
                //     // return updatedAssets;
                // });

                // for(let i = 0; i < fixedAssets.length; i++){
                //     let total = await fixedAssets[i].amountOfProject;
                //     let deprValue = await total * (rateofDepriciation[i])/100;
                //     let WDV_Closing = await total - deprValue;
                //     // SetWDV_Closing(WDV_Closing);
                //     fixedAssets[i].depreciationAllowable = deprValue;
                //     fixedAssets[i].WDV_Closing = WDV_Closing;
                // }
                console.log(fixedAssets);

                }}>

          
                {rateOfDepreciation.map((rateOfDeprec, index) => (
                <option key={index} value={rateOfDeprec}>{rateOfDeprec}</option>
                ))}

                </select>
                                            </td> 
                                            <td style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}>{(asset.wdv_Opening).toFixed(2)}</td>
                                            <td style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}>{parseFloat((asset.additionsNote_A).toFixed(2))}</td>
                                            <td style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}>{(asset.deductionsNote_B).toFixed(2)}</td>
                                            <td style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}>{parseFloat((asset.total).toFixed(2))}</td>
                                            <td style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}>{(asset.depreciationAllowable).toFixed(2)}</td>
                                            <td style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}>{(asset.wdv_Closing).toFixed(2)}</td>
                                        </tr>
                                    ))
                                }


                            
                            
                            </tbody>
                            <tfoot style={{textAlign:"center"}}>
                <tr style={{ border: '1px solid black'}}>
                <td style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}><b>{"Total"}</b></td>
                <td style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}></td>
                <td style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}>{(FAssetsElement.reduce((element, curr) => curr.wdv_Opening + element, 0)).toFixed(2)}</td>
                <td style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}>{(FAssetsElement.reduce((element, curr) => curr.additionsNote_A + element, 0)).toFixed(2)}</td>
                <td style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}></td>
                <td style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}>{(FAssetsElement.reduce((element,curr) => curr.total + element,0)).toFixed(2)}</td>
                <td style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}>{(FAssetsElement.reduce((element,curr) => curr.depreciationAllowable + element,0)).toFixed(2)}</td>
                <td style={{ border: '1px solid black', width: "100px", backgroundColor: 'white'}}>{(FAssetsElement.reduce((element,curr) => curr.wdv_Closing + element,0)).toFixed(2)}</td>
                {/* <td>{assets.forEach()}</td> */}

                </tr>
{/* )) } */}
                
            </tfoot>
            </table>))}
            
            
            <br/><br/>
            

    </div>

    {isShowSelectBorrowerMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeMessageModal}>&times;</span>
                        
                            <h2 style={{ color: "green" }}>
                                Please select a borrower!..
                            </h2>

                            <center><button onClick={closeMessageModal} style={{backgroundColor: 'green', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}

    
    {isShowNoDataMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeNoDataModal}>&times;</span>
                        
                            <h2 style={{ color: "red" }}>
                                Data Not Found!..
                            </h2>

                            <center><button onClick={closeNoDataModal} style={{backgroundColor: 'red', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}

    {isShowDataMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeDataModal}>&times;</span>
                        
                            <h2 style={{ color: "green" }}>
                                Data Found!..
                            </h2>

                            <center><button onClick={closeDataModal} style={{backgroundColor: 'green', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}

    {isProjectsSaved && (<div className="modal-overlay">
          <div className="modal-content">
            <span className="close" onClick={closeSaveModal}>&times;</span>
            {isProjectItemsSavedSuccess ? <><h2 style={{color:"green"}}>Saved Successfully!!<br/><br/><br/><center><FcApproval /></center></h2><center><button onClick={closeSaveModal} style={{backgroundColor: 'green', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center></>:
            <><h2 style={{color:"red"}}>Saving Failed!!<br/><br/><br/><br/><center><ImCross /></center></h2><center><button onClick={closeSaveModal} style={{backgroundColor: 'red', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center></>}
            

          <br/>
          <br/>
          </div>
        </div>)}
    </>
);

      }


export default FixedAssets;