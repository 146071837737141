import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import CreditMonitoringAnalysisSystemFrontPage from './CreditMonitoringAnalysisSystemFrontPage';
import reportWebVitals from './reportWebVitals';
import HamburgerMenu from './HamburgerMenu';
// import CreditMonitoringSystem from './CreditMonitoringSystem';

// const App = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <div className="menu-icon" onClick={toggleMenu}>
//       {isOpen ? <CreditMonitoringAnalysisSystemFrontPage /> : <HamburgerMenu />}
//     </div>
//   );
// };

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    {/* <CreditMonitoringSystem/> */}
    <HamburgerMenu/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
