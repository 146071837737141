import { useState } from "react";
import React, { useRef } from 'react';
import html2pdf from 'html2pdf.js';
// import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas';

function CashFlow(Borrower){

    const [periodOfLoan, setPeriodOfLoan] = useState(0);
    const [projectedAmount, setProjectedAmount] = useState([]);
    const [grossRevenue, SetGrossRevenue] = useState([]);
    const [totalOperatingExpenses, SetTotalOperatingExpenses] = useState([]);
    const [provisionForTax, SetProvisionForTax] = useState([]);
    const [tabData, SetTabData] = useState([]);
    const [newProjectedAmount, setNewProjectedAmount] = useState([]);
    // const [drawings, SetDrawings] = useState([]);
    const [drawings, setDrawings] = useState('');
    const [val, setVal] = useState([]);
    const [repaymentVariables, setRepaymentVariables] = useState({});
    const [periodAndMonthArr, setPeriodAndMonthArr] = useState([]);
    const [emiPeriodDetail, SetEmiPeriodDetail] = useState({});
    const [closingCash, SetClosingCash] = useState('');
    const [totalGSTValue, SetTotalGstValue] = useState(0.0);
    const [flagNetSurPlus, SetFlagNetSurPlus] = useState(true);
    const [isShowSelectBorrowerMessage, SetIsShowSelectBorrowerMessage] = useState(false);
    const [isShowDenialMessage, SetIsShowDenialMessage] = useState(false);
    const [isShowCashFlowButton, SetIsShowCashFlowButton] = useState(false);
    const [isShowNoDataMessage, SetIsShowNoDataMessage] = useState(false);
    const [buttonFlag, SetButtonFlag] = useState(false);
    const [IsShowButton, SetIsShowButton] = useState(true);

    let cashFlowRow = ["Operating Cash Flow", "Operating inflows :", "Gross revenue", "Other income(Non operating)", "Net Operating Inflows       (A)",
        "  ", "  ", "Operating Outflows", "Total operating expenses / Total cost of production", "  ", "Net Operating Outflows       (B)", "Net Operating Cash Flow (A - B) = (C)",
        "    ", "Add:", "Change in stock", "Change in creditors", "change in debtors", "Tax", " Drawings", "Total Operating Cash Flows (C + D)",
        "Investment Cash Flows", "Assets purchased (Principal loan amount)", "CI/GST", "down payment", "capital introduced", "Total Investment Cash Flows      (E)",
        "    ", "Financing Cash Flows", "    ", "(Interest Paid)", "Interest on Inf", "Total Financing Cash Flows       (F)", "NET SURPLUS / DEFICIT (G) = (C + D - E - F)",
        "Add: opening cash", "Closing cash"
    ];

    let borrowerDetailProjectItem = {};
borrowerDetailProjectItem.BorrowerId = Borrower.BorrowerId;

// async function getBalanceSheetDetails(){
//     try {
//         if(Borrower.BorrowerId !== -1){
//             let reqBody = {};
//             reqBody.BorrowerId = Borrower.BorrowerId;
//             const response = await fetch('http://localhost:8080/api/v1/creditMonitoringAnalysis/getBalanceSheetDetail',{
//               method: "POST",
//               mode: "cors",
//               headers: {
//                 "Content-Type": "application/json",
//               },
//               body: JSON.stringify(reqBody)
//             });
          
//             // debugger
//             if(response.ok){
//               const data = await response.json();
//               if(data.Message === "Success") {
//                 // let drawings = data.Drawings;
//                 // if(drawings === '' || drawings === null){
//                 //     SetIsShowDenialMessage(true);
//                 // }else{
//                 //     SetDrawings(JSON.parse(drawings));
//                 // }
//               }else if(data.Message === "Failure") {
//                 SetIsShowDenialMessage(true);
//               }
//             }
//         }else{
//             SetIsShowSelectBorrowerMessage(true);
//         }
//     } catch (error) {
//         alert(error);
//     }
//   }
const contentRef = useRef();
const generatePDF = () => {
    const input = contentRef.current; // Capture the DOM element

    const options = {
        margin: 0.2,
        filename: 'CashFlow.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' }
    };

    html2pdf().set(options).from(input).save();

    // Convert the content to canvas using html2canvas
    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jsPDF('p', 'mm', 'a4');
    //   const imgWidth = 240; // Width of the A4 page in mm
    //   const pageHeight = 297; // Height of A4 page in mm
    //   const imgHeight = 2*(canvas.height * imgWidth) / canvas.width;
    //   let heightLeft = imgHeight;
    //   let position = 0;

    //   // Add the image to the PDF
    //   pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
    //   heightLeft -= pageHeight;

    //   // If the content exceeds one page, add additional pages
    //   while (heightLeft > 0) {
    //     position = heightLeft - imgHeight;
    //     pdf.addPage();
    //     pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
    //     heightLeft -= pageHeight;
    //   }

    //   // Save the PDF file
    //   pdf.save('CashFlow.pdf');
    // });
  };

async function getRepaymentScheduleDetails(){

 try {
    if(Borrower.BorrowerId !== -1){
        let reqBody = {};
        reqBody.BorrowerId = Borrower.BorrowerId;
        const response = await fetch("https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/getEmiPeriod",{method:"POST",
        mode:"cors",
        headers: {
          "Content-Type": "application/json",
          },
          body: JSON.stringify(reqBody)
    }).then((response) =>
    { 
    console.log(response);
    if(response.ok){
      console.log("Success");
    }else{
      console.log("Failure");
    }
    return response.json();
    }
    ).then((data) => {
    console.log(data.body);
    if(data.Message === "Success"){
      console.log("Data: Success");
    }
    setRepaymentVariables(data.sourceOfFunds);
    console.log(repaymentVariables);
    setPeriodAndMonthArr(data.PeriodAndMonth);
    return data;
    });
    console.log(response);
        }else{
            SetIsShowSelectBorrowerMessage(true);
        }
 } catch (error) {
    alert(error);
 }
}

const getEmiDetails = async () => {
    try {
      if(Borrower.BorrowerId !== -1){
        let reqBody = {};
        reqBody.BorrowerId = Borrower.BorrowerId;
        const response = await fetch("https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/getEmiPeriodDetails",{method:"POST",
        mode:"cors",
        headers: {
          "Content-Type": "application/json",
          },
          body: JSON.stringify(reqBody)
    }).then
    ((response) =>
    { 
     console.log(response);
     if(response.ok){
       console.log("Success");
     }else{
       console.log("Failure");
     }
     return response.json();
    }
    ).then((data) => {
    console.log(data.Message, data);
    if(data.Message === 'Failure'){
        SetIsShowNoDataMessage(true);
    }else if(data.Message === 'Success'){
        SetIsShowNoDataMessage(false);
      SetEmiPeriodDetail(data.EmiPeriodDetails);
      
    }
    });
    console.log(response);
      }else{
        SetIsShowSelectBorrowerMessage(true);
      }
    } catch (error) {
      alert(error);
    }
  }

const getSourceFunds = async () => {
    try {
      
      if(Borrower.BorrowerId !== -1){
      let reqBody = {};
      reqBody.BorrowerId = Borrower.BorrowerId;
      const response = await fetch("https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/getSourceOfFunds", {
        method: "POST",
        mode: 'cors',
        headers:{
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody)
    }).then((response) => {
      if(response.ok){
        return response.json();
      }
    }).then((data) => {
      if(data.Message === 'Success'){
        SetTotalGstValue(data.SourceOfFunds.totalGstValue);
      }else if(data.Message === 'Failure'){
        SetIsShowNoDataMessage(true);
      }
    });
    console.log(response);
    }else{
        SetIsShowSelectBorrowerMessage(true);
    } }catch (error) {
      alert(error);
    }
  }

  function closeNoDataModal(){
    SetIsShowNoDataMessage(false);
  }

async function getOperatingStatementDetails(){
    try {
        if(Borrower.BorrowerId !== -1){
            let reqBody = {};
          reqBody.BorrowerId = Borrower.BorrowerId;
          const response = await fetch ('https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/getOperatingStatementDetail', {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(reqBody)
          });
          if(response.ok){
            const data = await response.json();
            if(data.Message === "Success"){
                let netSales = data.NetSales;
                let subTotal = data.SubTotal;
                let provisionForTax = data.ProvisionForTax;
                let flag = false;
                let secondFlag = 0;
                console.log(data);
                if(netSales === '' || netSales === null){
                    SetIsShowDenialMessage(true);
                    flag = true;
                    SetIsShowCashFlowButton(false);
                }else{
                    SetGrossRevenue(JSON.parse(netSales));
                    secondFlag = secondFlag + 1;
                }
                if(subTotal === '' || subTotal === null){
                    if(!flag){
                        SetIsShowDenialMessage(true);
                    }
                    SetIsShowCashFlowButton(false);
                }else{
                    SetTotalOperatingExpenses(JSON.parse(subTotal));
                    secondFlag = secondFlag + 1;
                }
                if(provisionForTax === '' || provisionForTax === null){
                    if(!flag){
                        SetIsShowDenialMessage(true);
                    }
                    SetIsShowCashFlowButton(false);
                }else{
                    SetProvisionForTax(JSON.parse(provisionForTax));
                    secondFlag = secondFlag + 1;
                }
        
                if(secondFlag === 3){
                    SetIsShowCashFlowButton(true);
                }
                console.log("Success");
            }else if(data.Message === "Failure"){
                console.log("Failure");
            }
          }
        }else{
            SetIsShowSelectBorrowerMessage(true);
        }
    } catch (error) {
        alert(error);
    }
    
}
function closeDenialMessageModal(){
    SetIsShowDenialMessage(false);
  }
    async function periodOfLoanf() {
        try {
            if(Borrower.BorrowerId !== -1){
                const response = await fetch('https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/getPeriodOfLoan', {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(borrowerDetailProjectItem)
                });
            
                if (response.ok) {
                    const data = await response.json();
                    console.log(data);
                    if (data.Message === "Success") {
                        setPeriodOfLoan(data.PeriodOfLoan);
                        console.log(data.PeriodOfLoan);
                        let UpdateProjectedAmount = ["Particulars", "Estimated"];
                        for (let i = 0; i < data.PeriodOfLoan; i++) {
                            UpdateProjectedAmount.push("Projected");
                        }
                        setProjectedAmount(UpdateProjectedAmount);
                        // for(let i = 0; i < data.PeriodOfLoan; i++){
                        //     periods.push(i)
                        // }
                        
                    } else {
                        setPeriodOfLoan(0);
                    }
                } else {
                    console.log("Failure");
                }
                console.log("PeriodOfLoan: "+ periodOfLoan);
            }else{
                SetIsShowSelectBorrowerMessage(true);
            }
        } catch (error) {
            alert(error);
        }
}

    async function saveCashFlowDetails(){
        try {
            if(Borrower.BorrowerId !== -1){
                let reqBody = {};
            reqBody.BorrowerId = Borrower.BorrowerId;
            reqBody.closingCash = closingCash;
            reqBody.drawings = drawings;
            const response = await fetch('https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/saveCashFlowDetail',{
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(reqBody)
            });
        //   debugger
            if(response.ok){
              const data = await response.json();
              if(data.Message === "Success") {
                alert('Values saved successfully');
              }else if(data.Message === "Failure"){
                alert('Values did not saved');
              }
            }
            }else{
                SetIsShowSelectBorrowerMessage(true);
            }
        } catch (error) {
            alert(error);
        }        
      }

      function closeMessageModal(){
        SetIsShowSelectBorrowerMessage(false);
      }
    function SetTableData(){
        try {
            if(Borrower.BorrowerId !== -1){
                let projectAmount = [];
                for(let i = 0; i < projectedAmount.length; i++){
                    if(i <= 1)
                    projectAmount[i] = projectedAmount[i];
                    else{
                        projectAmount[i] = projectedAmount[i] + i;
                    }
                }
                setNewProjectedAmount(projectAmount);
                let tab = [];
                for(let i = 0; i < cashFlowRow.length; i++){
                    let rowObj = {};
                    for(let k = 0, l = 0; k < projectedAmount.length; k++){
                        let value = projectAmount[k];
                        rowFinding(i,k);
                        // debugger
                        function rowFinding(i, k){
                            if(k > 0){
                                rowObj[value] = parseFloat(0);
                            }else if(k === 0){
                                // debugger
                                rowObj[value] = cashFlowRow[i];
                            }
                            // if(i === 0 || i === 1 || i === 5 || i === 6 || i === 7 || i === 9 || i === 12 || i === 13 || i === 20 || i === 23 || i === 24 || i === 26 || i === 27 || i === 28){
                            //     rowObj[value] = "";
                            // }
                            if((i === 2 || i === 4) && k >= 1){
                                rowObj[value] = parseFloat(parseFloat(grossRevenue[k]).toFixed(2));
                            }else if((i === 8 || i === 10) && k >= 1){
                                debugger
                                rowObj[value] = parseFloat(parseFloat(totalOperatingExpenses[k]).toFixed(2));
                            }else if(i === 11 && k >= 1){
                                rowObj[value] = parseFloat((parseFloat(parseFloat(grossRevenue[k]).toFixed(2)) - parseFloat(parseFloat(totalOperatingExpenses[k]).toFixed(2))).toFixed(2));
                            }else if(i === 15 && k >= 1){
                                // debugger
                                if(l === 0){
                                    rowObj[value] = parseFloat(periodAndMonthArr[l].PrincipalAmount.toFixed(2));
                                    l++;
                                }else if(l > 0){
                                    rowObj[value] = parseFloat(periodAndMonthArr[l].PrincipalAmount.toFixed(2)) - parseFloat(periodAndMonthArr[l - 1].PrincipalAmount.toFixed(2));
                                    rowObj[value] = parseFloat(rowObj[value].toFixed(2));
                                    l++;
                                }
                            }else if(i === 17 && k >= 2){
                                rowObj[value] = parseFloat(provisionForTax[k - 1].toFixed(2));
                            }
                            else if(i === 21 && k >= 1){
                                rowObj[value] = parseFloat(periodAndMonthArr[k - 1].PrincipalAmount.toFixed(2));
                            }else if(i === 22 && k === 1){
                                if(emiPeriodDetail.gstItc === 'Yes'){
                                    rowObj[value] = parseFloat(totalGSTValue.toFixed(2));
                                }
                            }else if(i === 25 && k >= 1){
                                let tab1 = [...tab];
                                let objPrincipalLoanAmount = tab1[21];
                                let objGst = tab1[22];
                                rowObj[value] = parseFloat((parseFloat(objPrincipalLoanAmount[value]) - parseFloat(objGst[value])).toFixed(2));
                            }else if((i === 29 || i === 31) && k >= 1){
                                rowObj[value] = parseFloat(periodAndMonthArr[k - 1].Interest.toFixed(2));
                            }
                            // else if(i === 18 && k >= 1){
                            //     rowObj[value] = parseFloat(drawings[k]);
                            // }
                            // else if(i === 19 && k >= 1){
                            //     let rowObjTop = {};
                            //     let rowObjA = {};
                            //     let rowObjB = {};
                            //     debugger
                            //     rowObjTop = tab[11];
                            //     for(let l = 14; l < 17; l++){
                            //         rowObjA = tab[l];
                            //         rowObjB = tab[++l];
                            //         // total = tab[19];
                            //         for(let j = 1; j < projectedAmount.length; j++){
                            //             if(j === 1){
                            //                 rowObj[value] = parseFloat(rowObjA["Estimated"]) + parseFloat(rowObjB["Estimated"]) + parseFloat(rowObj[value]);
                            //             }else{
                            //                 rowObj[value] = parseFloat(rowObjA["Projected" + j]) + parseFloat(rowObjB["Projected" + j])  + parseFloat(rowObj[value]);
                            //             }
                            //         }
                            //     }
                            //     for(let j = 1; j < projectedAmount.length; j++){
                            //         if(j === 1){
                            //             rowObj[value] = parseFloat(rowObj[value]) + parseFloat(rowObjTop["Estimated"]); //+ parseFloat(total["Estimated Rs"]) ;
                            //         }else{
                            //             rowObj[value] = parseFloat(rowObj[value]) + parseFloat(rowObjTop["Projected" + j]); //+ parseFloat(total["Projected Rs" + k]);
                            //         }
                            //     }
                            //     for(let j = 1; j < projectedAmount.length; j++){
                            //         if(j === 1){
                            //             rowObj[value] = parseFloat(rowObj[value]) - parseFloat(drawings[j]); //+ parseFloat(total["Estimated Rs"]) ;
                            //             rowObj[value] = parseFloat(rowObj[value].toFixed(2));
                            //         }else{
                            //             rowObj[value] = parseFloat(rowObj[value]) - parseFloat(drawings[j]); //+ parseFloat(total["Projected Rs" + k]);
                            //             rowObj[value] = parseFloat(rowObj[value].toFixed(2));
                            //         }
                            //     }
                            // }
                        }
                    }
                    tab.push(rowObj);
                }
                console.log(tab);
                SetFlagNetSurPlus(true);
                SetTabData(tab);
            }else{
                SetIsShowSelectBorrowerMessage(true);
            }
        } catch (error) {
            alert(error);
        }
    }

    function handleChange(e, indice){
        // debugger
        let values = [...val];
        values[indice] = parseFloat(parseFloat(e.target.value).toFixed(2));
        setVal(values);
        console.log(values);
        let tab = [...tabData];
        let rowObj = {};
        let obj = {};
        let obj1 = {};
        let obj2 = {};
        let obj3 = {};
        let obj4 = {};
        obj = tab[14];
        obj1 = tab[15];
        obj2 = tab[16];
        obj3 = tab[17];
        obj4 = tab[11];
        rowObj = tab[19];
        for(let i = 1; i <= indice; i++){
            if(i === 1){
                rowObj["Estimated"] = (obj["Estimated"] + obj1["Estimated"] + obj2["Estimated"] - obj3["Estimated"] + obj4["Estimated"]) - values[i];
                rowObj["Estimated"] = parseFloat(rowObj["Estimated"].toFixed(2));
            }else{
                rowObj["Projected" + i] = (obj["Projected" + i] + obj1["Projected" + i] + obj2["Projected" + i] - obj3["Projected" + i] + obj4["Projected" + i]) - values[i];
                rowObj["Projected" + i] = parseFloat(rowObj["Projected" + i].toFixed(2));
            }
        }
        SetTabData(tab);

        let drawings = [];

        for(let i = 0; i < projectedAmount.length; i++){
            if(i === 0){
                drawings.push("Drawings");
            }else{
                drawings.push(values[i]);
            }
        }

        setDrawings(JSON.stringify(drawings));
        console.log(JSON.stringify(drawings));

    }

    // function setTotalOperatingCashFlows(){
    //     try {
    //         if(Borrower.BorrowerId !== -1){
    //             if(tabData.length !== 0){
    //         let tab = [...tabData];
    //         let values = [...drawings];
    //         let rowObjTop = {};
    //         let total = {};
    //         let rowObjA = {};
    //         let rowObjB = {};
    //         // let rowObjDrawing = {};
    //         rowObjTop = tab[11];
    //         // rowObjDrawing = tab[18];
    //         // debugger
    //         for(let l = 14; l < 17; l++){
    //             rowObjA = tab[l];
    //             rowObjB = tab[++l];
    //             total = tab[19];
    //             for(let j = 1; j < projectedAmount.length; j++){
    //                 if(j === 1){
    //                     total["Estimated"] = parseFloat(rowObjA["Estimated"]) + parseFloat(rowObjB["Estimated"]) + parseFloat(total["Estimated"]);
    //                     total["Estimated"] = parseFloat(total["Estimated"].toFixed(2));
    //                 }else{
    //                     total["Projected" + j] = parseFloat(rowObjA["Projected" + j]) + parseFloat(rowObjB["Projected" + j]) + parseFloat(total["Projected" + j]);
    //                     total["Projected" + j] = parseFloat(total["Projected" + j].toFixed(2));
    //                 }
    //             }
    //         }
    //         for(let j = 1; j < projectedAmount.length; j++){
    //             if(j === 1){
    //                 total["Estimated"] = parseFloat(total["Estimated"]) + parseFloat(rowObjTop["Estimated"]); //+ parseFloat(total["Estimated Rs"]) ;
    //                 total["Estimated"] = parseFloat(total["Estimated"].toFixed(2));
    //             }else{
    //                 total["Projected" + j] = parseFloat(total["Projected" + j]) + parseFloat(rowObjTop["Projected" + j]); //+ parseFloat(total["Projected Rs" + k]);
    //                 total["Projected" + j] = parseFloat(total["Projected" + j].toFixed(2));
    //             }
    //         }
    //         for(let j = 1; j < projectedAmount.length; j++){
    //             if(j === 1){
    //                 total["Estimated"] = parseFloat(total["Estimated"]) - parseFloat(values[j]); //+ parseFloat(total["Estimated Rs"]) ;
    //                 total["Estimated"] = parseFloat(total["Estimated"].toFixed(2));
    //             }else{
    //                 total["Projected" + j] = parseFloat(total["Projected" + j]) - parseFloat(values[j]); //+ parseFloat(total["Projected Rs" + k]);
    //                 total["Projected" + j] = parseFloat(total["Projected" + j].toFixed(2));
    //             }
    //         }
    //         SetTabData(tab);
    //     }else{
    //         SetIsShowDenialMessage(true);
    //     }
    //     }else{
    //         SetIsShowSelectBorrowerMessage(true);
    //     }
    //     } catch (error) {
    //         alert(error);
    //     }
    // }

    function setFinancingCashFlows(){
        try {
            if(Borrower.BorrowerId !== -1){
                if(tabData.length !== 0 ){
            let rowObjA = {};
            let rowObjB = {};
            let rowObjC = {};
            let rowObjD = {};
            let rowObjE = {};
            let total = {};
            let tab = [...tabData];
            rowObjA = tab[19];
            rowObjB = tab[25];
            rowObjC = tab[31];
            rowObjD = tab[33];
            rowObjE = tab[34];
            total = tab[32];
            if(flagNetSurPlus){
                for(let j = 1; j < projectedAmount.length; j++){
                    if(j === 1){
                        total["Estimated"] = parseFloat(total["Estimated"]) + parseFloat(rowObjA["Estimated"]); //+ parseFloat(total["Estimated Rs"]) ;
                        total["Estimated"] = parseFloat((total["Estimated"]).toFixed(2));
                    }else{
                        total["Projected" + j] = parseFloat(total["Projected" + j]) + parseFloat(rowObjA["Projected" + j]); //+ parseFloat(total["Projected Rs" + k]);
                        total["Projected" + j] = parseFloat((total["Projected" + j]).toFixed(2));
                    }
                }
                for(let j = 1; j < projectedAmount.length; j++){
                    if(j === 1){
                        total["Estimated"] = parseFloat(total["Estimated"]) - parseFloat(rowObjB["Estimated"]); //+ parseFloat(total["Estimated Rs"]) ;
                        total["Estimated"] = parseFloat((total["Estimated"]).toFixed(2));
                    }else{
                        total["Projected" + j] = parseFloat(total["Projected" + j]) - parseFloat(rowObjB["Projected" + j]); //+ parseFloat(total["Projected Rs" + k]);
                        total["Projected" + j] = parseFloat((total["Projected" + j]).toFixed(2));
                    }
                }
                for(let j = 1; j < projectedAmount.length; j++){
                    if(j === 1){
                        total["Estimated"] = parseFloat(total["Estimated"]) - parseFloat(rowObjC["Estimated"]); //+ parseFloat(total["Estimated Rs"]) ;
                        total["Estimated"] = parseFloat((total["Estimated"]).toFixed(2));
                    }else{
                        total["Projected" + j] = parseFloat(total["Projected" + j]) - parseFloat(rowObjC["Projected" + j]); //+ parseFloat(total["Projected Rs" + k]);
                        total["Projected" + j] = parseFloat((total["Projected" + j]).toFixed(2));
                    }
                }
                SetFlagNetSurPlus(false);
            }
            // debugger
            for(let j = 1; j < projectedAmount.length; j++){
                if(j === 1){
                    rowObjE["Estimated"] = parseFloat(total["Estimated"]) + parseFloat(rowObjD["Estimated"]);
                    rowObjE["Estimated"] = parseFloat(rowObjE["Estimated"].toFixed(2))
                    rowObjD["Projected" + (j + 1)] = parseFloat(rowObjE["Estimated"].toFixed(2));
                }else{
                    rowObjE["Projected" + j] = parseFloat(total["Projected" + j]) + parseFloat(rowObjD["Projected" + j]);
                    rowObjE["Projected" + j] = parseFloat(rowObjE["Projected" + j].toFixed(2));
                    if((j + 1) < projectedAmount.length)
                    rowObjD["Projected" + (j + 1)] = parseFloat(rowObjE["Projected" + j].toFixed(2));
                }
            }
            let closingCash = [];
            for(let i = 0; i < projectedAmount.length; i++){
                if(i === 0){
                    closingCash.push("Closing Cash");
                }else if(i === 1){
                    closingCash.push(rowObjE["Estimated"]);
                }else if(i >= 2){
                    closingCash.push(rowObjE["Projected" + i])
                }
            }
            SetClosingCash(JSON.stringify(closingCash));
            SetTabData(tab);
        }else{
            SetIsShowDenialMessage(true);
        }
    }else{
        SetIsShowSelectBorrowerMessage(true);
    }
        } catch (error) {
            alert(error);
        }
}
    
    // getRepaymentScheduleDetails();
    
    // const handlePrint = () => {
    //     // Capture the content you want to print
    //     // const content = contentRef.current;
    
    //     // Open print dialog
    //     window.print();
    //   };

      const hideButtons = () => {
        SetIsShowButton(!buttonFlag);
        SetButtonFlag(!buttonFlag);
      }

    return (
        <>
        {IsShowButton && <>
        <button onClick={periodOfLoanf}>1. GetPeriodOfLoan</button>
        <button onClick={getOperatingStatementDetails}>2. GetOperatingStatementDetail</button>
        <button onClick={getRepaymentScheduleDetails}>3. GetRepaymentScheduleDetail</button>
        <button onClick={getSourceFunds}>4. GetTotalGst</button>
        <button onClick={getEmiDetails}>5. GetEmiDetails</button>
        {/* <button onClick={getBalanceSheetDetails}>4. GetBalanceSheetDetail</button> */}
        {isShowCashFlowButton && <button onClick={SetTableData}>6. GetCashFlow</button>}
        {/* <button onClick={setTotalOperatingCashFlows}>5. SetTotalOperatingCashFlows</button> */}
        <button onClick={setFinancingCashFlows}>7. SetFinancingCashFlows</button>
        <button onClick={saveCashFlowDetails}>8. SaveCashFlowDetail</button>
        <button onClick={generatePDF}>9. DownloadAsPdf</button></>}
        <button onClick={hideButtons}>...</button>
        <div ref={contentRef} style={{padding: '20px'}}>
            <br/>
            <span>
                <center><b>CashFlow</b></center>
            </span>
            <table>
                <thead>
                    {projectedAmount.map((element, _index) => (
                        // <tr key={index}>
                            <td style={{ border: '1px solid black', width: '100px' }}>{element} </td>
                        // </tr>
                    ))}
                </thead>
                <tbody>
                    {/* {cashFlowRow.map((element, index) => (
                    <tr key={index}>
                        <th style={{ border: '1px solid black', width: "100px", backgroundColor: 'greenyellow'}}>{element} </th>
                    </tr>))} */}
                    {
                        tabData.map((element, index) => <tr key={index}>
                            {
                                newProjectedAmount.map((column, indice) => 
                                <th style={{border: '1px solid black', width: "100px"}}>
                                    {
                                    ((index === 18 && indice >= 1 )) ? <input
                                style={{ width: '100px', height: '20px', padding: '2px', margin: '5px', backgroundColor: 'redyellow' }}
                                type="number"
            value={val[indice]} // Bind the input value to the state
            onChange={(e) => handleChange(e, indice)} // Handle input changes
          />:
                                        element[column]
                                    }
                                </th>)
                            }
                        </tr>)
                    }
                </tbody>
            </table>
        </div>
        {isShowSelectBorrowerMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeMessageModal}>&times;</span>
                        
                            <h2 style={{ color: "green" }}>
                                Please select a borrower!..
                            </h2>

                            <center><button onClick={closeMessageModal} style={{backgroundColor: 'green', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}

{isShowDenialMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeDenialMessageModal}>&times;</span>
                        
                            <h2 style={{ color: "red" }}>
                            Project Data is Missing!..
                            </h2>

                            <center><button onClick={closeDenialMessageModal} style={{backgroundColor: 'red', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}


{isShowNoDataMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeNoDataModal}>&times;</span>
                        
                            <h2 style={{ color: "red" }}>
                                Data Not Found!..
                            </h2>

                            <center><button onClick={closeNoDataModal} style={{backgroundColor: 'red', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}
        </>
    );

}

export default CashFlow;