import {React, useState, useEffect} from "react";
import { FaBars, FaTimes } from 'react-icons/fa';
import './HamburgerMenu.css';
import {BrowserRouter, Link, Route, Routes} from 'react-router-dom';
import RegisterBorrower from "./RegisterBorrower";
import ShowBorrowerDetails from "./ShowBorrowerDetails";
import BorrowerProfile from "./BorrowerProfile"
import CostOfProject from "./CostOfTheProject";
import RepaymentSchedule from "./RepaymentSchedule";
import FixedAssets from "./FixedAssets";
import OperatingStatement from "./OperatingStatement";
import CreditMonitoringSystem from "./CreditMonitoringSystem";
// import OperatingStatementDetails from "./OperatingStatementDetails";
import './menu.css';
import BalanceSheet from "./BalanceSheet";
import CashFlow from "./CashFlow";
import Ratios from "./Ratios";
// import PDFGenerator from "./PDFGenerator";
// import WorkingCapitalAnalysis from "./WorkingCapitalAnalysis";

// const router = createBrowserRouter(
//     createRoutesFromElements(
//         <Route>
//             <Route path = '/' element = {<RegisterBorrower/>}>
//                 </Route>
//             <Route path = 'showBorrowerDetails' element = {<ShowBorrowerDetails/>}/>
//             <Route path = 'costOfTheProject' element = {<CostOfProject/>}/>
//             <Route path = 'repaymentSchedule' element = {<RepaymentSchedule />} />
//         </Route>
//     ))

const HamburgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };

// function Menu(){

    const [borrowerId, setborrowerId] = useState(-1);
    const [prevKey, setPrevKey] = useState(-1);
    const [isCostOfProj, setIsCostOfProj] = useState(false);
    // const [borrowerIds, setBorrowerIds] = useState([]);

    console.log('borrowerId: '+ borrowerId);

    function setborrowerIdPrevKey(borrowerId, prevKey){
        setborrowerId(borrowerId);
        setPrevKey(prevKey);
    }

    useEffect(() => {
        // Check if borrowerId is set and update isCostOfProj accordingly
        setIsCostOfProj(borrowerId !== -1);
        
    }, [borrowerId]);
    
    return(
        <BrowserRouter >
        {/* // <RouterProvider router={router}> */}
            <header >
            <div className="hamburger-menu">
      <div className="menu-icon" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>
      <nav className={`nav-menu ${isOpen ? 'open' : ''}`}>
        <ul style={{display:'inlince-block', padding: "5px", margin: "5px"}}>
            <div className="navTabLink">
            <li style={{display:'inline-grid'}}>
                <Link to='RegisterBorrowerDetails' style={{textDecoration:"none"}} onClick={toggleMenu}>RegisterBorrowerDetails</Link>
                {/* <a href="/">RegisterBorrowerDetails</a> */}
            </li>
            </div>
            <div className="navTabLink">
            <li style={{display:'inline-grid'}}>
                <Link to='showBorrowerDetails' style={{textDecoration:"none"}} onClick={toggleMenu}>ShowBorrowerDetails</Link>
                {/* <a href="showBorrowerDetails">ShowBorrowerDetails</a> */}
            </li>
            </div>
           {(isCostOfProj && <><div className="navTabLink">
            <li style={{display:'inline-grid'}}>
                <Link to="borrowerProfile" style={{textDecoration:"none"}} onClick={toggleMenu}>
                    BorrowerProfile
                </Link>
            </li>
            </div>
            <div className="navTabLink">
            <li style={{display:'inline-grid'}}>
                <Link to="costOfTheProject" style={{textDecoration:"none"}} onClick={toggleMenu}>
                    CostOfTheProject
                </Link>
            </li>
            </div>
            <div className="navTabLink">
            <li style={{display:'inline-grid'}}>
                <Link to="repaymentSchedule" style={{textDecoration:"none"}} onClick={toggleMenu}>
                    RepaymentSchedule
                </Link>
            </li>
            </div>
            <div className="navTabLink">
            <li style={{display:'inline-grid'}}>
                <Link to={"fixedAssets"} style={{textDecoration:"none"}} onClick={toggleMenu}> FixedAssets</Link>
            </li>
            </div>
            <div className="navTabLink">
            <li style={{display:'inline-grid'}}>
                <Link to={"operatingStatement"} style={{textDecoration:"none"}} onClick={toggleMenu}> OperatingStatement</Link>
            </li>
            </div>
            <div className="navTabLink">
            <li style={{display:'inline-grid'}}>
                <Link to={"cashFlow"} style={{textDecoration:"none"}} onClick={toggleMenu}> CashFlow</Link>
            </li>
            </div>
            <div className="navTabLink">
            <li style={{display:'inline-grid'}}>
                <Link to={"balanceSheet"} style={{textDecoration:"none"}} onClick={toggleMenu}> BalanceSheet</Link>
            </li>
            </div>
            <div className="navTabLink">
            <li style={{display:'inline-grid'}}>
                <Link to={"ratios"} style={{textDecoration:"none"}} onClick={toggleMenu}> Ratios</Link>
            </li>
            </div>
            {/* <div className="navTabLink">
            <li style={{display:'inline-grid'}}>
                <Link to={"savePdf"} style={{textDecoration:"none"}} onClick={toggleMenu}> SavePdf</Link>
            </li>
            </div> */}
            </>)}
            {/* <div className="navTabLink">
            <li style={{display:'inline-grid'}}>
                <Link to={"workingCapitalAnalysis"} style={{textDecoration:"none"}}> WorkingCapitalAnalysis</Link>
            </li>
            </div> */}
            {/* <div className="navTabLink">
            <li style={{display:'inline-grid'}}>
                <Link to={"operatingStatementDetail"} style={{textDecoration:"none"}}> OperatingStatementDetail</Link>
            </li>
            </div> */}
        </ul>
        </nav>
    </div>
        </header>
        <main>
        <Routes>
            <Route index element = {<CreditMonitoringSystem/>}>
                </Route>
            <Route path = 'RegisterBorrowerDetails' element = {<RegisterBorrower />}/>    
            <Route path = 'showBorrowerDetails' element = {<ShowBorrowerDetails  BorrowerIdPrevKey = {setborrowerIdPrevKey} PrevKey = {prevKey}/>}/>
            <Route path = 'borrowerProfile' element = {<BorrowerProfile BorrowerId={borrowerId}/>}/>
            <Route path = 'costOfTheProject' element = {<CostOfProject  BorrowerId={borrowerId}/>}/>
            <Route path = 'repaymentSchedule' element = {<RepaymentSchedule BorrowerId={borrowerId}/>} />
            <Route path = 'fixedAssets' element= {<FixedAssets BorrowerId={borrowerId}/>}/>
            <Route path="operatingStatement" element = {<OperatingStatement BorrowerId={borrowerId}/>}/>
            <Route path="balanceSheet" element = {<BalanceSheet BorrowerId = {borrowerId}/>}/>
            <Route path="cashFlow" element = {<CashFlow BorrowerId = {borrowerId}/>}/>
            <Route path="ratios" element = {<Ratios BorrowerId = {borrowerId}/>}/>
            {/* <Route path="savePdf" element = {<PDFGenerator/>}/> */}
            {/* <Route path="workingCapitalAnalysis" element = { <WorkingCapitalAnalysis BorrowerId = {borrowerId}/>}/> */}
            {/* <Route path="operatingStatementDetail" element = { <OperatingStatementDetails />}/> */}
        </Routes>
        </main>
        
        {/* <RouterProvider router={router}></RouterProvider> */}
        
         </BrowserRouter>
        // </RouterProvider>
    );
}

export default HamburgerMenu;