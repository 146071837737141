import {React, useState} from 'react';
import { FcApproval } from "react-icons/fc";
import { ImCross } from "react-icons/im";
import { CgSmileSad } from "react-icons/cg";

function RegisterBorrower({borrowerDetails}){

    const [PurposeOfLoan, SetPurposeOfLoan] = useState('');
 // const [costOfProject ,SetCostOfProject] = useState('');
  const [BorrowerName ,SetBorrowerName] = useState('');
  const [Firm_sName, SetFirm_sName] = useState('');
  const [RegisteredAddress ,SetRegisteredAddress] = useState('');
  const [Pan ,SetPan] = useState('');
  const [AboutBorrower, SetAboutBorrower] = useState('');
  const [isUserSaved, SetUserSaved] = useState(false);
  const [isPanNew, SetPanNew] = useState(false);
  const [isSomeThingWentWrong, SetSomethingWentWrong] = useState(false);
  const [isShowCheckFieldMessage, SetIsShowCheckFieldMessage] = useState(false);

  const [isNewBorrower, setNewBorrower] = useState(false);




async function handleSave(){

    const reqObj = {};
    reqObj.borrowerName = BorrowerName;
    reqObj.firm_sName = Firm_sName;
    reqObj.borrowerAddress = RegisteredAddress;
    reqObj.borrowerPan = Pan;
    reqObj.purposeOfLoan = PurposeOfLoan;
    reqObj.aboutBorrower = AboutBorrower;
    console.log(JSON.stringify(reqObj));
    if(BorrowerName === '' || Firm_sName === '' || RegisteredAddress === '' || Pan === '' || PurposeOfLoan === '' || AboutBorrower === ''){
      SetIsShowCheckFieldMessage(true);
    }else{
      const response = await fetch("https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/addingBorrower",
        {
         method:"POST",
         mode:"cors",
         headers: {
           "Content-Type": "application/json",
           },
           body: JSON.stringify(reqObj)
         })
         .then((respons) =>{ 
           
           console.log(respons)
           console.log(respons.body)
         
           if(respons.ok){
             console.log("Success")
             // console.log(respons.json())
           }else{
             console.log("Failure")
           }
           return respons.json();
         }).then((data) => {console.log(data)
             return data;
         })

         .catch((error) => console.error(error))           
         console.log(response.Message);
         SetUserSaved(true);
         if(response.Message === 'User Not Found'){
           SetPanNew(true);
         }
         else if(response.Message === 'Failure'){
           SetSomethingWentWrong(true);
           SetUserSaved(false);
         }else if(response.Message === 'User Found'){
           SetPanNew(false);
         }
    }
    // let element = document.getElementsByClassName("AppForm");
}

function addNewBorrower(){
  setNewBorrower(true);
}

function closeModal(){
  SetUserSaved(false);
}

function closeModalForSomethingWentWrong(){
  SetSomethingWentWrong(false);
}

function closeCheckFieldModal(){
  SetIsShowCheckFieldMessage(false);
}

    return(
    <div className='AppForm'>
    {/* <div style = {{display: displayValue}}> */}
    <span>
      RegisterBorrower
    </span>
    <br/>
    <br/>
    <br/>
    
    <button  style={{ width: '140px', height: '50px', borderRadius: '5px', padding: '15px', textAlign: 'center' }}onClick={addNewBorrower}>Add New Borrower</button>
    {isNewBorrower && <div>
    <h4>Borrower's Name</h4>
      <input style = {{width : "700px",height: "30px", borderRadius:'5px', padding: '5px'}} value = {BorrowerName} onChange={e => SetBorrowerName(e.target.value)} required></input>
      <h4>Borrower's Firm Name</h4>
      <input style = {{width : "700px",height: "30px", borderRadius:'5px', padding: '5px'}} value = {Firm_sName} onChange={e => SetFirm_sName(e.target.value)} required></input>
    <h4>Registered Address</h4>
      <input style = {{width : "700px",height: "30px", borderRadius:'5px', padding: '5px'}} value = {RegisteredAddress} onChange={e => SetRegisteredAddress(e.target.value)} required></input>
    <h4>Pan</h4>
      <input style = {{width : "700px",height: "30px", borderRadius:'5px', padding: '5px'}} value = {Pan} onChange={e => SetPan(e.target.value)} required></input>
      <h4>About the Project / Purpose of Loan</h4>
      <input style = {{width : "700px",height: "30px", borderRadius:'5px', padding: '5px'}} value = {PurposeOfLoan} onChange={e => SetPurposeOfLoan(e.target.value)} required></input>
      <h4>About the Borrower</h4>
      <input style = {{width : "700px",height: "30px", borderRadius:'5px', padding: '5px'}} value = {AboutBorrower} onChange={e => SetAboutBorrower(e.target.value)} required></input>
      <br/><br/>
      <button
            style={{ width: '100px', height: '45px', borderRadius: '5px', padding: '15px', textAlign: 'center' }}
            onClick={handleSave}
          >
            Save
          </button>
          </div>}
          {isUserSaved && (<div className="modal-overlay">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            {isPanNew ? <h2 style={{color:"green"}}>New User Saved Successfully!!<br/><br/><br/><center><FcApproval /></center><br/><br/>
            <center><button onClick={closeModal} style={{backgroundColor: 'green', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center></h2>
            :<h2 style={{color:"red"}}>User Saving Failed!! as user already found..<br/><br/><br/><br/><center><ImCross /></center><br/><br/>
            <center><button onClick={closeModal} style={{backgroundColor: 'red', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center></h2>}
            

          <br/>
          <br/>
          </div>
        </div>)}
        {isSomeThingWentWrong && (<div className="modal-overlay">
          <div className="modal-content">
            <span className="close" onClick={closeModalForSomethingWentWrong}>&times;</span>
            Oops...!! Something went wrong. Please try again..<br/><br/><br/><center><CgSmileSad /></center>
            
            <center><button onClick={closeModalForSomethingWentWrong} style={{backgroundColor: 'green', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
          <br/>
          <br/>
          </div>
        </div>)}

        {isShowCheckFieldMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeCheckFieldModal}>&times;</span>
                        
                            <h2 style={{ color: "red" }}>
                                Please Fill The Empty Field(s)...! 
                            </h2>

                            <center><button onClick={closeCheckFieldModal} style={{backgroundColor: 'red', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}


      </div>
    );
}

export default RegisterBorrower;