import {React, useState} from 'react'
import Card from './Card'

function ShowBorrowerDetails({BorrowerIdPrevKey, PrevKey}){
    let borrowersList;
    let [borrowers, setBorrowers] = useState([]);
    let [borrowersDetail, setBorrowerDetails] = useState([]);
    const [lastKey, setLastKey] = useState(-1);
    let showBorrowers = [];
    const [searchInput, setSear̥chInput] = useState('');
    // const [Key, setKey] = useState(0);
    // let isClicked = false;
    async function reload(){
        try {
            borrowersList = await fetch("https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/getBorrowers"
                // ,{
                //     method:"GET",
                //     mode:"cors",
                    // headers: {
                    //   "Content-Type": "application/json",
                    //   },
                    //   body: JSON.stringify(reqObj)
                    // }
                    ).then((response) => {
                        console.log(response.body);
                        if(response.ok){
                            console.log('Success');
                        }else{
                            console.log('Failure');
                        }
                        return response.json();
                    }).then((data) => {
                        console.log(data);
                        if(data.Message === 'Success'){
                            setBorrowers(data.Borrowers);
                            showBorrowers = [...data.Borrowers];
                            setBorrowerDetails(data.Borrowers);
                            setSear̥chInput('');
                            setLastKey(data.Borrowers.length - 1);
                        }
                        return data;
                    })
        
                    console.log(borrowersList);
        } catch (error) {
            alert(error);
        }
        
    }

    // function handleClickBorrower(element){

    //     if(!isClicked){
    //         isClicked = true;
    //         getBorrowerId(element.BorrowerId);
    //     }else{
    //         isClicked = false;
    //         getBorrowerId(-1);
    //     }

    // }

    console.log(borrowersList);

    // else if(element.borrowerName.includes(e.target.value))
    // else if(element.borrowerPan.includes(e.target.value))
    // else if(element.purposeOfLoan.includes(e.target.value))
    const handleSear̥chInput = (e) => {
        setSear̥chInput(e.target.value);
        showBorrowers = borrowers.filter((element) => 
            {
                console.log(element);

                if(element.borrowerName === null)
                     return false;
                
                else if(element.borrowerName.indexOf(e.target.value) !== -1){
                    console.log(element.borrowerName);
                    return true;
                }
                else if(element.borrowerPan === null)
                     return false;
                
                else if(element.borrowerPan.indexOf(e.target.value) !== -1)
                     return true;
                else if(element.purposeOfLoan === null)
                     return false;
                
                else if(element.purposeOfLoan.indexOf(e.target.value) !== -1)
                     return true; 
                else return false;       
            }

        )

        setBorrowerDetails(showBorrowers);

    }

    return(
        <>
        <h3>
            ShowBorrowerDetails
        </h3>
        <br/>
        <span>Search Here:</span>
        <input style={{ width: '250px', height: '25px',margin:'55px', borderRadius: '15px', textAlign: 'center', color: 'black', backgroundColor: 'White' }} 
        type='text' value={searchInput} onChange={handleSear̥chInput}/>
        <div>
            {/* <h4 style={{textAlign:"center"}}>Baidyanath Kumar Pandey</h4>
            <br/>
            <h4 style={{textAlign:"center"}}>Village - Jari, P. O. - Purio, P. S. - Ratu, Ranchi</h4>
            <br/>
            <h4 style={{textAlign:"center"}}>HSJMJ9238P</h4> */}
            <button  style={{ width: '140px', height: '50px', borderRadius: '5px', padding: '15px', textAlign: 'center', color: 'blue', backgroundColor: 'LightGrey' }}onClick={reload}>Reload</button>

        </div>
        
        <ul style={{display:"inline-block", alignItems: "center",width: "100%", padding:"15px", justifyContent:"center"}}>
            {/* <ul style={{display:'grid'}}> */}
                {borrowersDetail.map((element, index) => (
                    // console.log(element);
                     <li key = {index} style={{display:'inline-grid', justifyContent:"center", alignItems: "center", padding:"5px"}}> <Card borrower={element} BorrowerId_prevKey={BorrowerIdPrevKey} Key={index} prevKey = {PrevKey} LastKey = {lastKey}/></li>
                    //  <li key = {index} style={{display:'grid', justifyContent:"center", alignItems: "center", padding:"5px"}}> <Card borrower={element} BorrowerId={getBorrowerId} Key={index}/></li>
          ))}
            {/* </ul> */}
        </ul>
        </>
    );
}

export default ShowBorrowerDetails;