import React, { useState, useEffect } from 'react';
// import { FaBars} from 'react-icons/fa';
// import HamburgerMenu from './HamburgerMenu';
import './CreditMonitoringAnalysisSystemFrontPage.css'
// import { BrowserRouter, Link, Routes, Route } from 'react-router-dom';
// import RegisterBorrower from "./RegisterBorrower";
// import ShowBorrowerDetails from "./ShowBorrowerDetails";

// const menuOptions = [
//   "Register borrower details",
//   "Show borrower details",
//   "Cost of the project",
//   "Repayment schedule",
//   "Fixed assets",
//   "Operating assets",
//   "Balance sheet",
//   "Cash flow",
//   "Ratios"
// ];

// const HamburgerIcon = ({ onClick }) => (
//   <button onClick={onClick} style={{
//     background: 'none',
//     border: 'none',
//     cursor: 'pointer',
//     position: 'fixed',
//     top: '20px',
//     left: '20px',
//     zIndex: 1000
//   }}>
//     <FaBars />
//   </button>
// );

// const HamburgerMenu = ({ isOpen, onOptionClick }) => {
//   if (!isOpen) return null;

//   return (
//     <div style={{
//       position: 'fixed',
//       top: 0,
//       left: 0,
//       width: '250px',
//       height: '100%',
//       backgroundColor: 'black',
//       color: 'white',
//       padding: '60px 20px 20px',
//       boxSizing: 'border-box',
//       display: 'flex',
//       flexDirection: 'column',
//       gap: '10px',
//       zIndex: 999
//     }}>
//       {menuOptions.map((option, index) => (
//         <button 
//           key={index}
//           onClick={() => onOptionClick(option)}
//           style={{
//             background: 'none',
//             border: 'none',
//             color: 'white',
//             textAlign: 'left',
//             padding: '10px',
//             cursor: 'pointer',
//             transition: 'background-color 0.3s'
//           }}
//           onMouseEnter={(e) => e.target.style.backgroundColor = '#333'}
//           onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}
//         >
//           {option}
//         </button>
//       ))}
//     </div>
//   );
// };

const CreditMonitoringSystem = () => {
  const [greeting, setGreeting] = useState('');
  // const [currentPage, setCurrentPage] = useState('home');
  // const [isMenuOpen, setIsMenuOpen] = useState(false);

  // const options = [
  //   "#RegisterBorrower",
  //   "#ShowBorrowerDetails"
  // ];
  
  // const [showRegisterBorrower, SetShowRegisterBorrower] = useState(false);
  // const [showBorrowerDetails, SetShowBorrowerDetails] = useState(false);
  
  // const Options = [
  //   "Register borrower details",
  //   "Show borrower details"
  // ];

  useEffect(() => {
    const updateGreeting = () => {
      const currentHour = new Date().getHours();
      if (currentHour >= 0 && currentHour < 12) {
        setGreeting('Good morning');
      } else if (currentHour >= 12 && currentHour < 17) {
        setGreeting('Good afternoon');
      } else {
        setGreeting('Good evening');
      }
    };

    updateGreeting();
    const intervalId = setInterval(updateGreeting, 60000);
    return () => clearInterval(intervalId);
  }, []);

  // const handleCardClick = (option) => {
  //   setCurrentPage(option);
  // };

  // const handleAnchorClick = (e) => {
  //   e.preventDefault();
  //   SetShowRegisterBorrower(!showRegisterBorrower);
  //   SetShowBorrowerDetails(!showBorrowerDetails);
  // }


  // if (currentPage === 'home') {
    return (
      
      <div style={{
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '2rem 1rem',
        fontFamily: 'Arial, sans-serif',
      }}>
        <div style={{
          textAlign: 'center',
          marginBottom: '3rem',
        }}>
          <h1 style={{
            fontSize: '1.875rem',
            fontWeight: 'bold',
            marginBottom: '0.5rem',
          }}>
            Hello, {greeting}!<br />
            Welcome to the
          </h1>
          <br />
          <br />
          <h2 style={{
            fontSize: '2.25rem',
            fontWeight: 'bold',
            color: "pink"
          }}
          className='card-animation'>
            Credit Monitoring Analysis System
          </h2>
        </div>
      </div>
    );
  // }
};

export default CreditMonitoringSystem;