import React, {useState } from "react";
import { useRef } from 'react';
import html2pdf from 'html2pdf.js';
// import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas';
// import DataTable from "react-data-table-component";

function OperatingStatement(Borrower){

    
    const [periodOfLoan, setPeriodOfLoan] = useState(0);
    const [projectedAmount, setProjectedAmount] = useState([]);
    // const [period, setPeriod] = useState(0);
    const [val, Setval] = useState([]);
    const [subTotalVal, setSubTotalVal] = useState([]);
    const [aboutSales, setAboutSales] = useState('');
    const [amountOfSales, setAmountOfSales] = useState(0.0);
    const [projects, setProjects] = useState([]);
    const [borrowerProfile, SetBorrowerProfile] = useState({});
    const [totalCostOfProject, setTotalCostOfProject] = useState(0.0);
    let taxValueNext = 0.0;
    let taxValue = 0.0;
    const [emiResponse, setEmiResponse] = useState();
    const [periodAndMonthArr, setPeriodAndMonthArr] = useState([]);
    const [depreciationAllowable, SetDepreciationAllowable] = useState([]);
    // const [increPercent, setIncrePercent] = useState(0);
    const [revenuePercent, setRevenuePercent] = useState(0);
    const [expensePercent, setExpensePercent] = useState(0);
    const [isgetInputFlag, setGetInputFlag] = useState(false);
    const [buttonFlag, SetButtonFlag] = useState(false);
    const [IsShowButton, SetIsShowButton] = useState(true);
    const [isShowSelectBorrowerMessage, SetIsShowSelectBorrowerMessage] = useState(false);
    const [isShowNoDataMessage, SetIsShowNoDataMessage] = useState(false);

    const [isShowDenialMessage, SetIsShowDenialMessage] = useState(false);

    const [isInputForOs, SetIsInputForOs] = useState(false);
    const [isInputReady, setInputReady] = useState(false);
    const [ValVar, SetValVar] = useState([]);
    const [isFinalTableReady, SetFinalTableReady] = useState(false);
    const [operRow, SetOperRow] = useState([]);
    // const [valVarIndex, SetValVarIndex] = useState(0);
    // const [columns, SetColumns] = useState([]); 
    const [tableData, SetTableData] = useState([]);
    // const [projectsValues, SetProjectsValues] = useState([]);
    let periods = [];
    const [newColumns, SetNewColumns] = useState([]);
    const [netprofit, SetNetProfit] = useState('');
    const [netSales, SetNetSales] = useState('');
    const [subTotal, SetSubTotal] = useState('');
    const [operatingProfitBeforeTax, SetOperatingProfitBeforeTax] = useState('');
    const [operatingProfitAfterTax, SetOperatingProfitAfterTax] = useState('');
    const [provisionForTax, SetProvisionForTax] = useState('');
    const [operatingProfitBeforeDITax, SetOperatingProfitBeforeDITax] = useState('');
    // const [number_of_rows, setNo_of_rows] = useState(0);
    let Period = 0;
    // const [index, setIndex] = useState(0);

    // setIndex(0);
    // let element = '';
    // const NoOfYearsUsed = [0, 1, 2, 4, 5, 8, 10, 15]
    console.log(Period);
    const rateOfIncrement = [0, 5, 10, 15, 20, 25, 40];
    const operationRow = ["1) Gross Receipt", "2) Less Duty and Taxes", "3) NET SALES (1-2)(TOTAL)",
     "4) COST OF SALES :", "SUB-TOTAL",
    "Add : Opening Work in progress & Rm", "SUB-TOTAL", "LESS : Closing Stock in Progress & Rm", "SUB-TOTAL : COST OF PRODUCTION",
    "Add : Opening Stock in Finished Goods", "SUB-TOTAL", "Less : Closing Stock in Finished Goods", "SUB-TOTAL", "COST OF SALES",
    "5)Selling, General & Admn. Expenses(incl bonus payments) and loan charges", "6) Misc. Exp. & Prel. Exp. W/off Balance written off",
    "7) Operating Profit before Depn, Int. & Taxes (OPBDIT) [3-4-5-6]", "8)Interest Cost Of Which", "a) Interest on Cash Credit",
     "b) Interest on Long Term & other Borrowings", "9) Depreciation", "10) Operating Profit before Tax [7-8-9]", "11) Other Non-Operating Income/Expenses",
    "a) Other / Non-operating Income (Interest Received)", "b) Other / Non-operating Expenses", "SUB-TOTAL (a-b)", "12) PROFIT BEFORE TAX [10 + 11]",
    "13)Provision for Development Rebate / Inv. Allowances", "14) Provision For Tax", "15) NET PROFIT [12-13-14]", "16) Appropriations",
    "a) Dividends", "b) Tax on Dividend", "c) Other Provisions (specify)", "d) Other Appropriations (capital reserve) (specify)", 
    "17)Net Profit Retained In The Business [15-16]"];
    

    const months = ["April", "May", "June", "July", "August", "September", "October", "November", "December", "January", "February", "March"];
    let borrowerDetailProjectItem = {};
borrowerDetailProjectItem.BorrowerId = Borrower.BorrowerId;

// function 

// const handleIncrementChangePercent = (event) => {
//     setIncrePercent(parseFloat(event.target.value));
//   }; 

  const handleRevenueChangePercent = (event) => {
    setRevenuePercent(parseFloat(event.target.value));
  };

  const handleExpenseChangePercent = (event) => {
    setExpensePercent(parseFloat(event.target.value));
  };

//   function inintValArr(){
//     setNo_of_rows(projects.length);
//   // Initialize val[] and subTotalVal[] as nested arrays with dimensions [number_of_rows][period]
// const initialVal = Array.from({ length: number_of_rows + 37}, () => Array(Period + 1).fill(0));
// const initialSubTotalVal = Array.from({ length: number_of_rows + 37}, () => Array(Period + 1).fill(0));
// Setval(initialVal);
// setSubTotalVal(initialSubTotalVal);
//   }
// inintValArr();

// useEffect(() => {
//     // Call the initialization function once periodOfLoan and number_of_rows are set
//     inintValArr();
//   });

//   useEffect(() => {
//     // Perform async operations after the component mounts
//     periodOfLoanf();
//     getRepaymentScheduleDetails();
//     getDepreciationAllowable();
//   });

const getDepreciationAllowable = async () => {

  try {
    // console.log(Borrower.BorrowerId);
    if(Borrower.BorrowerId !== -1){
      let reqBody = {};
      reqBody.BorrowerId = Borrower.BorrowerId;
      const response = await fetch("https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/getDepreciationValues",{method:"POST",
      mode:"cors",
      headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody)
  }).then
  ((response) =>
  { 
   console.log(response);
   if(response.ok){
     console.log("Success");
   }else{
     console.log("Failure");
   }
   return response.json();
  }
  ).then((data) => {
  console.log(data.Message, data);
  let depreciation = data.Depreciation;
  if(depreciation === '' || depreciation === null){
    SetIsShowDenialMessage(true);
    SetIsInputForOs(false);
  }
  else{
    depreciation = JSON.parse(depreciation);
    SetIsInputForOs(true);
  }  
  SetDepreciationAllowable(depreciation);
  console.log(depreciationAllowable);
  });
  console.log(response);
    }else{
      SetIsShowSelectBorrowerMessage(true);
    }
  } catch (error) {
    alert(error);
  }

  
}

async function getRepaymentScheduleDetails(){

  try {
    if(Borrower.BorrowerId !== -1){
      let reqBody = {};
      reqBody.BorrowerId = Borrower.BorrowerId;
      const response = await fetch("https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/getEmiPeriod",{method:"POST",
      mode:"cors",
      headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody)
  }).then((response) =>
  { 
  console.log(response);
  if(response.ok){
    console.log("Success");
  }else{
    console.log("Failure");
  }
  return response.json();
  }
  ).then((data) => {
  console.log(data.body);
  if(data.Message === "Success"){
    console.log("Data: Success");
    const sOf = data.sourceOfFunds;
  let period = sOf.year;
  // setPeriod(period);
  Period = period;
  setEmiResponse(data.EmiResponse);
  setPeriodAndMonthArr(data.PeriodAndMonth);
  SetIsInputForOs(true);
  console.log("Period: " + period);
  }else if(data.Message === 'Data Not Found'){
    SetIsShowDenialMessage(true);
    SetIsInputForOs(false);
  }
  return data;
  });
  console.log(periodAndMonthArr);
  console.log(response);
    }
    getUserProfile();
  } catch (error) {
    alert(error);
  }    
}

const getUserProfile = async () => {
  try {
      if(Borrower.BorrowerId !== -1){
          let reqObj = {};
          reqObj.BorrowerId = Borrower.BorrowerId;

          const response = await fetch("https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/getBorrowerProfile", {method: 'POST',
              mode: 'cors',
              headers:{
                  "Content-Type": "application/json",
              },
              body: JSON.stringify(reqObj)
      }).then((response) => {
          // debugger
          if(response.ok)
          return response.json();
      }).then((data) => {
          if(data.Message === 'Success'){
              SetBorrowerProfile(data.Profile);
          }else if(data.Message === 'Failure'){
              SetIsShowNoDataMessage(true);
          }
      });

      console.log(response);
      }else{
        SetIsShowSelectBorrowerMessage(true);
      }
  } catch (error) {
      alert(error);
  }
}

function closeNoDataModal(){
  SetIsShowNoDataMessage(false);
}

async function saveStatementDetails(){
  try {
    if(Borrower.BorrowerId !== -1){
      let reqBody = {};
    reqBody.BorrowerId = Borrower.BorrowerId;
    reqBody.netProfit = netprofit;
    reqBody.netSales = netSales;
    reqBody.subTotal = subTotal;
    reqBody.operatingProfitBeforeTax = operatingProfitBeforeTax;
    reqBody.operatingProfitAfterTax = operatingProfitAfterTax;
    reqBody.provisionForTax = provisionForTax;
    reqBody.operatingProfitBeforeDITax = operatingProfitBeforeDITax;
    const response = await fetch('https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/saveOperatingStatementDetail',{
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody)
    });
  
    if(response.ok){
      const data = await response.json();
      if(data.Message === "Success") {
        alert('Values saved successfully');
      }
    }
    }else{
      SetIsShowSelectBorrowerMessage(true);
    }
  } catch (error) {
    alert(error);
  }
}
const contentRef = useRef();
// const headingContentRef = useRef();
const generatePDF = () => {
  const input = contentRef.current; // Capture the DOM element
  
  const options = {
    margin: 0.2,
    filename: 'OperatingStatement.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
};

html2pdf().set(options).from(input).save();
  // Convert the content to canvas using html2canvas
  // html2canvas(input).then((canvas) => {
  //   const imgData = canvas.toDataURL('image/png');
  //   const pdf = new jsPDF('p', 'mm', 'a4');
  //   const imgWidth = 210; // Width of the A4 page in mm
  //   const pageHeight = 297; // Height of A4 page in mm
  //   const imgHeight = 2*(canvas.height * imgWidth) / canvas.width;
  //   let heightLeft = imgHeight;
  //   let position = 0;

  //   // Add the image to the PDF
  //   pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //   heightLeft -= pageHeight;

  //   // If the content exceeds one page, add additional pages
  //   while (heightLeft > 0) {
  //     position = heightLeft - imgHeight;
  //     pdf.addPage();
  //     pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;
  //   }

  //   // Save the PDF file
  //   pdf.save('OperatingStatement.pdf');
  // });
};

async function periodOfLoanf() {
    const response = await fetch('https://www.lblpr.com/cmatl/api/v1/creditMonitoringAnalysis/getPeriodOfLoan', {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(borrowerDetailProjectItem)
    });

    if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data.Message === "Success") {
            setPeriodOfLoan(data.PeriodOfLoan);
            console.log(data.PeriodOfLoan);
            let UpdateProjectedAmount = ["Particulars", "Estimated"];
            for (let i = 0; i < data.PeriodOfLoan; i++) {
                UpdateProjectedAmount.push("Projected");
            }
            setProjectedAmount(UpdateProjectedAmount);
            // let col = [];
            for(let i = 0; i < data.PeriodOfLoan; i++){
                periods.push(i);
                // let columnObj = {};
                // columnObj.name = UpdateProjectedAmount[i];
                // columnObj.selector = row => row.UpdateProjectedAmount[i];
                // col.push(columnObj);
            }
            // SetColumns(col);

            let colum = [];
            for(let i = 0; i < UpdateProjectedAmount.length; i++){
              let columnObj = {};
              columnObj.name = UpdateProjectedAmount[i];
              colum.push(columnObj);
            }
            // SetColumns(colum);

            console.log(colum);
            let newColum = [];

            for(let i = 0; i < UpdateProjectedAmount.length; i++){
              if(i === 0 || i === 1){
                newColum.push(UpdateProjectedAmount[i]);
              }else{
                newColum.push(UpdateProjectedAmount[i] + i);
              }
            }

            console.log(newColum);

            SetNewColumns(newColum);

            let tabData = [];
            // for(let k = 0; k < newColum.length; k++){
            //   for(let z = 0; z < ValVar.length ; z++){
            //     let rowObj = {};
            //     for(let j = 0; j < data.PeriodOfLoan; j++){
            //       let value = newColum[k];
            //       // console.log(value);
            //       if(k === 0){
            //         rowObj[value] = operRow[z];
            //       }else if(k === 1){
            //         rowObj[value] = parseFloat(ValVar[z]);
            //       }else{
            //         let increament = 1 + (increPercent/100);
            //         console.log(increament);
            //         rowObj[value] = parseFloat(ValVar[z]) * increament;
            //       }
            //     }
            //     tabData.push(rowObj);
            //   }
            // }
            let netProfit = [];
            let netSales = [];
            let subTotal = [];
            let operatingProfitBeforeTax = [];
            let operatingProfitAfterTax = [];
            let provisionForTax = [];
            let operatingProfitBeforeDITax = [];
            // debugger
            let emiStartMonth = emiResponse.emiStartMonth;
            let month;
            for(let i = 0; i < 12; i++){
              if(emiStartMonth === months[i]){
                month = i;
                month = 12 - month;
                break;
              }
            }
            for(let z = 0; z < ValVar.length; z++){
              let rowObj = {};
              // let increament = 1 + (increPercent/100);
              let revenueIncreament = 1 + (revenuePercent/100);
              let expenseIncreament = 1 + (expensePercent/100);
              let valVarValue = ValVar[z];
              let deprePosition = 20 + projects.length; 
              // debugger
              for(let j = 0, k = 0, i = 0, l = 1; j < newColum.length; j++, k++, i++){
                let value = newColum[j];
                if(j === 0){
                  rowObj[value] = operRow[z];
                }else{
                  if(z === (13 + projects.length)){
                  // debugger
                  let obj = tabData[z-1];
                  rowObj[value] = obj[value];
                }else if(ValVar[z] === undefined || ValVar[z] === ""){
                    rowObj[value] = "";
                  }else if((z === 0 || z === 1 || z === 2) && j === 1){
                    rowObj[value] = parseFloat(valVarValue).toFixed(2);
                  }else if((z === 0 || z === 1 || z === 2) && j === 2){
                    // debugger
                    rowObj[value] = (parseFloat(valVarValue) * 12 / month * revenueIncreament).toFixed(2);
                    valVarValue = rowObj[value];
                  }else if((z === 0 || z === 1 || z === 2) && j > 2){
                    rowObj[value] = (parseFloat(valVarValue) * revenueIncreament).toFixed(2);
                    valVarValue = rowObj[value];
                  }else if((z > 3 && z <= (3 + projects.length + 1)) && l === 1){
                    rowObj[value] = parseFloat(valVarValue).toFixed(2);
                    l++;
                  }else if((z > 3 && z <= (3 + projects.length + 1)) && l === 2){
                    rowObj[value] = (parseFloat(valVarValue) * 12 / month * expenseIncreament).toFixed(2);
                    valVarValue = rowObj[value];
                    l++;
                  }else if((z > 3 && z <= (3 + projects.length + 1)) && l > 2){
                    rowObj[value] = (parseFloat(valVarValue) * expenseIncreament).toFixed(2);
                    valVarValue = rowObj[value];
                    l++;
                  }else if(z === (5 + projects.length + 1)){
                    let obj1 = tabData[z-1];
                    let obj2 = tabData[z-2];
                    rowObj[value] = (parseFloat(obj1[value]) + parseFloat(obj2[value])).toFixed(2);
                  }else if(z === (7 + projects.length + 1)){
                    let obj1 = tabData[z-1];
                    let obj2 = tabData[z-2];
                    rowObj[value] = (parseFloat(obj2[value]) - parseFloat(obj1[value])).toFixed(2);
                  }else if(z === (9 + projects.length + 1)){
                    let obj1 = tabData[z-1];
                    let obj2 = tabData[z-2];
                    rowObj[value] = (parseFloat(obj1[value]) + parseFloat(obj2[value])).toFixed(2);
                  }else if(z === (11 + projects.length + 1)){
                    let obj1 = tabData[z-1];
                    let obj2 = tabData[z-2];
                    rowObj[value] = (parseFloat(obj2[value]) - parseFloat(obj1[value])).toFixed(2);
                  }else if((z === (13 + projects.length + 1)) && l === 1){ 
                    rowObj[value] = (parseFloat(valVarValue)).toFixed(2);
                    l++;
                  }else if((z === (13 + projects.length + 1)) && l > 1){ 
                    rowObj[value] = (parseFloat(valVarValue) * expenseIncreament).toFixed(2);
                    valVarValue = rowObj[value];
                    l++;
                  }else if((z === (14 + projects.length + 1)) && l === 1){
                    rowObj[value] = (parseFloat(valVarValue)).toFixed(2);
                    l++;
                  }else if((z === (14 + projects.length + 1)) && l > 1){
                    rowObj[value] = (parseFloat(valVarValue) * expenseIncreament).toFixed(2);
                    valVarValue = rowObj[value];
                    l++;
                  }
                  else if(z === 15 + projects.length + 1){
                    let obj1 = tabData[2];
                    let obj2 = tabData[3 + projects.length + 1];
                    let obj3 = tabData[z-2];
                    let obj4 = tabData[z-1];
                    rowObj[value] = (parseFloat(obj1[value])-parseFloat(obj2[value])-parseFloat(obj3[value])-parseFloat(obj4[value])).toFixed(2);
                  }else if(z === 20 + projects.length + 1){
                    let obj1 = tabData[z-5];
                    let obj2 = tabData[z-3];
                    let obj3 = tabData[z-2];
                    let obj4 = tabData[z-1];
                    rowObj[value] = (parseFloat(obj1[value]) - (parseFloat(obj2[value]) + parseFloat(obj3[value])) - parseFloat(obj4[value])).toFixed(2);
                  }else if(z === 25 + projects.length + 1){
                    let obj1 = tabData[z-5];
                    let obj2 = tabData[z-1];
                    // let obj2 = tabData[z-3];
                    // let obj3 = tabData[z-2];
                    rowObj[value] = parseFloat((parseFloat(obj1[value]) + parseFloat(obj2[value])).toFixed(2));
                  }else if(z === 28 + projects.length + 1){
                    let obj1 = tabData[z-3];
                    let obj2 = tabData[z-2];
                    let obj3 = tabData[z-1];
                    rowObj[value] = (parseFloat(obj1[value]) - parseFloat(obj2[value]) - parseFloat(obj3[value])).toFixed(2);
                  }else if(z === 34 + projects.length + 1){
                    let obj1 = tabData[z-4];
                    let obj2 = tabData[z-3];
                    let obj3 = tabData[z-2];
                    let obj4 = tabData[z-1];
                    let obj5 = tabData[z-6];
                    rowObj[value] = (parseFloat(obj5[value]) - (parseFloat(obj1[value]) + parseFloat(obj2[value]) + parseFloat(obj3[value]) + parseFloat(obj4[value])));
                  }else if(z === deprePosition - 1){
                    rowObj[value] = periodAndMonthArr[i-1].Interest.toFixed(2);
                  }else if(z === deprePosition && k <= depreciationAllowable.length){
                    rowObj[value] = depreciationAllowable[k-1].toFixed(2);
                  }else if(z === 3 + projects.length + 1 + 24){
                    let obj = tabData[z-2];
                    rowObj[value] = provisionForTaxes(obj[value]);
                  }else{
                    rowObj[value] = parseFloat(valVarValue).toFixed(2);
                  }
                }
              }
              tabData.push(rowObj);
            }
            for(let j = 0; j < newColum.length; j++){
              if(j === 0){
                netProfit.push('Net Profit Retained In The Business');
              }else{
                let value = newColum[j];
                let obj = tabData[ValVar.length - 1];
                netProfit.push(obj[value]);   // ValVar[ValVar.length - 1]) * increament * j
              }
          }
          for(let j = 0; j < newColum.length; j++){
            if(j === 0){
              netSales.push('Net Sales');
            }else{
              let value = newColum[j];
              let obj = tabData[2];
              netSales.push(obj[value]);   // ValVar[ValVar.length - 1]) * increament * j
            }
        }
        for(let j = 0; j < newColum.length; j++){
          if(j === 0){
            subTotal.push('Sub-Total');
          }else{
            let value = newColum[j];
            let objTotalCostOfSales = tabData[3 + projects.length + 1];
            let objSelling = tabData[13 + projects.length + 1];
            let objMisc = tabData[14 + projects.length + 1];
            subTotal.push(parseFloat(objSelling[value]) + parseFloat(objMisc[value]) + parseFloat(objTotalCostOfSales[value]));   // ValVar[ValVar.length - 1]) * increament * j
          }
        }
        for(let j = 0; j < newColum.length; j++){
          if(j === 0){
            operatingProfitBeforeTax.push('Operating Profit Before Tax');
          }else{
            let value = newColum[j];
            let obj = tabData[3 + projects.length + 1 + 17];
            operatingProfitBeforeTax.push(obj[value]);   // ValVar[ValVar.length - 1]) * increament * j
          }
        }
        for(let j = 0; j < newColum.length;j++){
          if(j === 0){
            provisionForTax.push("Provision For Tax");
          }else{
            let value = newColum[j];
            let obj = tabData[3 + projects.length + 1 + 24];
            provisionForTax.push(obj[value]);
          }
        }
        for(let j = 0; j < newColum.length; j++){
          if(j === 0){
            operatingProfitAfterTax.push('Operating Profit After Tax');
          }else{
            let value = newColum[j];
            let obj = tabData[3 + projects.length + 1 + 31];
            operatingProfitAfterTax.push(obj[value]);   // ValVar[ValVar.length - 1]) * increament * j
          }
        }
        for(let j = 0; j < newColum.length; j++){
          if(j === 0){
            operatingProfitBeforeDITax.push('Operating Profit Before Depn, Int. & Taxes');
          }else{
            let value = newColum[j];
            let obj = tabData[15 + projects.length + 1];
            operatingProfitBeforeDITax.push(obj[value]);
          }
        }
        console.log(operatingProfitAfterTax);
        console.log(operatingProfitBeforeTax);
        SetSubTotal(JSON.stringify(subTotal));
        SetOperatingProfitAfterTax(JSON.stringify(operatingProfitAfterTax));
        SetOperatingProfitBeforeTax(JSON.stringify(operatingProfitBeforeTax));
        SetProvisionForTax(JSON.stringify(provisionForTax));
        SetOperatingProfitBeforeDITax(JSON.stringify(operatingProfitBeforeDITax));
        SetNetSales(JSON.stringify(netSales));
        console.log(JSON.stringify(netProfit));
        SetNetProfit(JSON.stringify(netProfit));
        console.log(netprofit);
        SetTableData(tabData);
        console.log(tableData);
        console.log(tabData);
        console.log(colum);
            
        } else {
            setPeriodOfLoan(0);
        }
    } else {
        console.log("Failure");
    }
    console.log("PeriodOfLoan: "+ periodOfLoan);
}

// getRepaymentScheduleDetails();

function closeDenialMessageModal(){
  SetIsShowDenialMessage(false);
}

// const handlePrint = () => {
//     // Capture the content you want to print
//     // const content = contentRef.current;

//     // Open print dialog
//     window.print();
//   };

//   function handleValChange(e, index){
//     let values = [...val];
//     values[index] = parseFloat(e.target.value);
//     Setval(values);
//     if(values.length > 1){
//         let val = values[0] + values[1];
//         // subTotalVal[0] = 0;
//         let value = [...subTotalVal];
//         value[0] = val;
//         setSubTotalVal(value);
//     }
// }

// function valVarIndexSet(){
//   SetValVarIndex(projects.length);
// }

function createValVar(){
    // let valVarValues = [];
    let value = [...val];
    let subtotal = [...subTotalVal];
    console.log(val);
    console.log(subTotalVal);
    for(let i = 0, j = 0; i < 36 && j < 12; i++){
       
      if(value[i] === undefined && (i === 30 || i === 3 || i === 13 || i === 17 || i === 22)){
        value[i] = '';
      }else if(value[i] === undefined && (i !== 3 || i !== 13 || i !== 17 || i !== 22 || i !== 30)){
        value[i] = subtotal[j];
        j++;
      }
      // else if(value[i] === undefined && (i === 3 || i === 13 || i === 17 || i === 22 || i === 30)){
      //     value[i] = '';
      // }
       
    }
    // SetValVar(value);
    console.log(ValVar);
    // value = [];
    // let values = [...ValVar];
    // let flag = true;
    // for(let i = 0, k =0; i < ValVar.length + projects.length; i++){
    //   if(k === 4 && flag){
    //     for(let j = 0; j < projects.length; j++){
    //       value[i++] = projects[j].amountOfSales;
    //     }
    //     flag = false;
    //     continue;
    //   }

    //   value[i] = values[k++];
    // }
    // let project = [];
    for(let i = 0; i < projects.length; i++){
      // project.push(projects[i].amountOfSales);
      value.splice(4+i, 0, projects[i].amountOfSales);
    }
    
    
    console.log(value);
    value[value.length] = subTotalVal[11] - subTotalVal[12];
    SetValVar(value);
    console.log(ValVar);
}

function handleVal23Change(e, index){
    let values = [...val];
    values[index] = parseFloat(e.target.value);
    // values[index + 1] = parseFloat(periodAndMonthArr[0].Interest.toFixed(2));
    // values[index + 2] = parseFloat(depreciationAllowable[0]);
    Setval(values);

    if (values.length > 31 && !isNaN(values[31]) && !isNaN(values[32]) && !isNaN(values[33]) && !isNaN(values[34])) {
        let sum = parseFloat(values[31] + values[32] + values[33] + values[34]); // Direct arithmetic addition
        let subtotal = sum.toFixed(2);
        let updatedSubTotalVal = [...subTotalVal];
        updatedSubTotalVal[12] = parseFloat(subtotal);
        setSubTotalVal(updatedSubTotalVal);
        console.log(subTotalVal);
}

    let array = JSON.stringify(val);
    console.log(array);

}

function handleVal21Change(e, index){
    let values = [...val];
    values[index] = parseFloat(e.target.value);
    // values[index + 1] = parseFloat(periodAndMonthArr[0].Interest.toFixed(2));
    // values[index + 2] = parseFloat(depreciationAllowable[0]);
    Setval(values);

    if (values.length > 26 && !isNaN(values[26]) && !isNaN(values[27])) {
        let sum = parseFloat(subTotalVal[9] - values[27] - values[28]); // Direct arithmetic addition
        let subtotal = sum.toFixed(2);
        let updatedSubTotalVal = [...subTotalVal];
        updatedSubTotalVal[10] = parseFloat(subtotal).toFixed(2);
        setSubTotalVal(updatedSubTotalVal);
        console.log(subTotalVal);
}}

function handleVal19Change(e, index){
    let values = [...val];
    values[index] = parseFloat(e.target.value);
    // values[index + 1] = parseFloat(periodAndMonthArr[0].Interest.toFixed(2));
    // values[index + 2] = parseFloat(depreciationAllowable[0]);
    Setval(values);

    if (values.length > 23 && !isNaN(values[23]) && !isNaN(values[24])) {
        let sum = parseFloat(values[23] - values[24]); // Direct arithmetic addition
        let subtotal = sum.toFixed(2);
        let updatedSubTotalVal = [...subTotalVal];
        updatedSubTotalVal[8] = parseFloat(subtotal).toFixed(2);
        setSubTotalVal(updatedSubTotalVal);
        console.log(subTotalVal);
}}

function handleVal17Change(e, index){
    let values = [...val];
    values[index] = parseFloat(e.target.value);
    values[index + 1] = parseFloat(periodAndMonthArr[0].Interest.toFixed(2));
    values[index + 2] = parseFloat(depreciationAllowable[0]);
    console.log("index:- ",index);
    Setval(values);

    console.log("subTotal", subTotalVal[6])
    // if(values.length > 18 && isNaN(values[19] && isNaN(values[20]))){
    //     let sum = parseFloat(subTotalVal[6] - val[18] - periodAndMonthArr[0].Interest.toFixed(2) - depreciationAllowable[0]);
    //     let subtotal = sum.toFixed(2);
    //     let updatedSubTotalVal = [...subTotalVal];
    //     updatedSubTotalVal[7] = parseFloat(subtotal);
    //     setSubTotalVal(updatedSubTotalVal);
    // }

}

function handleVal15Change(e, index){
    let values = [...val];
    values[index] = parseFloat(e.target.value);
    // values[index + 1] = parseFloat(periodAndMonthArr[0].Interest.toFixed(2));
    // values[index + 2] = parseFloat(depreciationAllowable[0]);
    Setval(values);

    if (values.length > 15 && !isNaN(values[14]) && !isNaN(values[15])) {
        let sum = parseFloat(subTotalVal[0] - subTotalVal[1] - values[14] - values[15]); // Direct arithmetic addition
        let subtotal = sum.toFixed(2);
        let updatedSubTotalVal = [...subTotalVal];
        updatedSubTotalVal[6] = parseFloat(subtotal).toFixed(2);
        setSubTotalVal(updatedSubTotalVal);
        console.log(subTotalVal, 15);
}}

function handleVal11Change(e, index){
    let values = [...val];
    values[index] = parseFloat(e.target.value);
    Setval(values);
    console.log(val, "val11");

    if (values.length > 11 && !isNaN(values[11])) {
        let sum = parseFloat(subTotalVal[4] - values[11]); // Direct arithmetic subtraction
        let subtotal = sum.toFixed(2);
        let updatedSubTotalVal = [...subTotalVal];
        updatedSubTotalVal[5] = parseFloat(subtotal).toFixed(2);
        setSubTotalVal(updatedSubTotalVal);
        console.log(subTotalVal, 11);
    }
}

function handleVal9Change(e, index){
    let values = [...val];
    values[index] = parseFloat(e.target.value);
    Setval(values);
    console.log(val, "val9");

    if (values.length > 9 && !isNaN(values[9])) {
        let sum = parseFloat(subTotalVal[3] + values[9]); // Direct arithmetic addition
        let subtotal = sum.toFixed(2);
        let updatedSubTotalVal = [...subTotalVal];
        updatedSubTotalVal[4] = parseFloat(subtotal).toFixed(2);
        setSubTotalVal(updatedSubTotalVal);
        console.log(subTotalVal, 9);
    }
}

function handleVal7Change(e, index){
    let values = [...val];
    values[index] = parseFloat(e.target.value);
    Setval(values);
    console.log(val, "val7");

    if (values.length > 7 && !isNaN(values[7])) {
        let sum = parseFloat(subTotalVal[2] - values[7]); // Direct arithmetic subtraction
        let subtotal = sum.toFixed(2);
        let updatedSubTotalVal = [...subTotalVal];
        updatedSubTotalVal[3] = parseFloat(subtotal).toFixed(2);
        setSubTotalVal(updatedSubTotalVal);
        console.log(subTotalVal, 7);
    }

}

function handleVal5Change(e, index){
    let values = [...val];
    values[index] = parseFloat(e.target.value);
    Setval(values);
    console.log(val, "val5");

    if (values.length > 5 && !isNaN(values[5])) {
        let sum = parseFloat(subTotalVal[1] + values[5]); // Direct arithmetic addition
        let subtotal = sum.toFixed(2);
        let updatedSubTotalVal = [...subTotalVal];
        updatedSubTotalVal[2] = parseFloat(subtotal).toFixed(2);
        setSubTotalVal(updatedSubTotalVal);
        console.log(subTotalVal, 5);
    }
}


function handleValChange(e, index) {
    let values = [...val];
    values[index] = parseFloat(e.target.value);
    Setval(values);
    console.log(val, "val");
    console.log(values.length);
    // setSubTotalVal(values);
    // Calculate subtotal if both values are available
  if (values.length > 1 && !isNaN(values[0]) && !isNaN(values[1])) {
        let sum = parseFloat(values[0]) - parseFloat(values[1]);
        let subtotal = sum.toFixed(2); // Round the sum to 2 decimal places
        console.log(subtotal);
        // console.log(subTotalVal);
        let updatedSubTotalVal = [...subTotalVal];
        updatedSubTotalVal[0] = parseFloat(subtotal).toFixed(2);
        console.log(updatedSubTotalVal, 1);
        setSubTotalVal(updatedSubTotalVal);

    } 
}

const provisionForTaxes = (income) => {
  if(income < 700000){
    let tax = parseFloat(0.00);
    return tax;
  }else if(income >= 700000){
    let taxValueNext = 0.0;
    let taxValue = 0.0;
    taxValue = ((700000 - 300000) * 5 / 100);
    taxValue = (parseFloat(taxValue) + parseFloat(taxValueNext));
    if(income <= 1000000){
      taxValueNext = (parseFloat(Math.abs(income - 700000) * 10 / 100));
      taxValue = (parseFloat(taxValue) + parseFloat(taxValueNext));
    }
    if(income <= 1200000){
      taxValueNext = (parseFloat(Math.abs(income - 1000000) * 15 / 100));
      taxValue = (parseFloat(taxValue) + parseFloat(taxValueNext));
    }
    if(income <= 1500000){
      taxValueNext = (parseFloat(Math.abs(income - 1200000) * 20 / 100));
      taxValue = (parseFloat(taxValue) + parseFloat(taxValueNext));
    }
    if(income > 1500000 ){
      taxValueNext = (parseFloat(Math.abs(income - 1500000) * 30 / 100));
      taxValue = (parseFloat(taxValue) + parseFloat(taxValueNext));
    }
    let educationCess = parseFloat(taxValue * 4 / 100);
    let tax = parseFloat(taxValue) + parseFloat(educationCess);
    tax = parseFloat(tax.toFixed(2));
    return tax;
  }
}

const refresh = () => {
    // Update total cost of the project
    setTotalCostOfProject(prevTotalCost => parseFloat( prevTotalCost ) + parseFloat(amountOfSales));

    let subtotal = [...subTotalVal];
 
    
    let value = [...val];
    subtotal[0] = parseFloat(value[0]) - parseFloat(value[1]);
    subtotal[1] = parseFloat(totalCostOfProject.toFixed(2));
    setSubTotalVal(subtotal);
    // debugger
    subtotal[2] = parseFloat(subtotal[1]) + parseFloat(value[5]);
    subtotal[2] = parseFloat(subtotal[2].toFixed(2));
    subtotal[3] = parseFloat(subtotal[2]) - parseFloat(value[7]);
    subtotal[3] = parseFloat(subtotal[3].toFixed(2));
    subtotal[4] = parseFloat(subtotal[3]) + parseFloat(value[9]);
    subtotal[4] = parseFloat(subtotal[4].toFixed(2));
    subtotal[5] = parseFloat(subtotal[4]) - parseFloat(value[11]);
    subtotal[5] = parseFloat(subtotal[5].toFixed(2));
    subtotal[6] = parseFloat(subtotal[0]) - parseFloat(subtotal[5]) - parseFloat(value[14]) - parseFloat(value[15]);
    subtotal[6] = parseFloat(subtotal[6].toFixed(2));
    console.log(subtotal[6], value[18], value[19], value[20]);
    // subtotal[7] = subtotal[6] - (value[18] + value[19]) - value[20];

    console.log(val[18], val[19]);
    subtotal[7] = parseFloat(subtotal[6]) - (parseFloat(val[18]) + parseFloat(val[19])) - parseFloat((val[20]));
    subtotal[7] = parseFloat(subtotal[7].toFixed(2));
    subtotal[8] = parseFloat(value[23]) - parseFloat(value[24]);
    subtotal[8] = parseFloat(subtotal[8].toFixed(2));
    subtotal[9] = parseFloat(subtotal[8]) + parseFloat(subtotal[7]);
    subtotal[9] = parseFloat(subtotal[9].toFixed(2)); 
    if(subtotal[9] < 700000){
      subtotal[10] = parseFloat(0.00);
    }else if(subtotal[9] >= 700000){
      taxValueNext = 0.0;
      taxValue = 0.0;
      taxValue = ((700000 - 300000) * 5 / 100);
      taxValue = (parseFloat(taxValue) + parseFloat(taxValueNext));
      if(subtotal[9] <= 1000000){
        taxValueNext = (parseFloat(Math.abs(subtotal[9] - 700000) * 10 / 100));
        taxValue = (parseFloat(taxValue) + parseFloat(taxValueNext));
      }
      if(subtotal[9] <= 1200000){
        taxValueNext = (parseFloat(Math.abs(subtotal[9] - 1000000) * 15 / 100));
        taxValue = (parseFloat(taxValue) + parseFloat(taxValueNext));
      }
      if(subtotal[9] <= 1500000){
        taxValueNext = (parseFloat(Math.abs(subtotal[9] - 1200000) * 20 / 100));
        taxValue = (parseFloat(taxValue) + parseFloat(taxValueNext));
      }
      if(subtotal[9] > 1500000 ){
        taxValueNext = (parseFloat(Math.abs(subtotal[9] - 1500000) * 30 / 100));
        taxValue = (parseFloat(taxValue) + parseFloat(taxValueNext));
      }
      let educationCess = parseFloat(taxValue * 4 / 100);
      subtotal[10] = parseFloat(taxValue) + parseFloat(educationCess);
      subtotal[10] = parseFloat(subtotal[10].toFixed(2));
    }
    subtotal[11] = parseFloat(subtotal[9]) - parseFloat(value[27]) -parseFloat(subtotal[10]);//- parseFloat(value[28]);
    subtotal[11] = parseFloat(subtotal[11].toFixed(2));
    setSubTotalVal(subtotal);
    // let values = [...val];
    // if (values.length > 14 && !isNaN(values[14]) && !isNaN(values[15])) {
    //     let sum = parseFloat(subTotalVal[0] - subTotalVal[1] - values[14] - values[15]); // Direct arithmetic addition
    //     let subtotal = sum.toFixed(2);
    //     let updatedSubTotalVal = [...subTotalVal];
    //     updatedSubTotalVal[6] = parseFloat(subtotal);
    //     setSubTotalVal(updatedSubTotalVal);
    //     console.log(subTotalVal, 15);
    // }
}

const getFinalTableReady = () => {

    SetFinalTableReady(true);
    setInputReady(false);
    console.log(isInputReady, isFinalTableReady);
    let operatnRow = [];
    let flag = true;
  //   const projectDetail = {
  //     productId: null,
  //     borrowerId: borrowerDetailProjectItem.BorrowerId,
  //     aboutSales: "",
  //     amountOfSales: "",
  //     isActive: 0
  // };
    // let projectsValue = [projectDetail, ...projects];
    // SetProjectsValues(projectsValue);
    // console.log(projectsValue[0].amountOfSales);
    for(let i = 0, k = 0; k < operationRow.length; i++){
        if(k === 4 && flag){
          // operatnRow[i++] = operationRow[k];
            for(let j = 0; j < projects.length; ){
                operatnRow[i++] = projects[j].aboutSales;
                j++;
            }
            flag = false;
            continue;
        }else if(k === 4 && !flag){
          operatnRow[i] = operationRow[++k];
        }

        operatnRow[i] = operationRow[k];
        k++;
        // if(k < 4){
        //   operatnRow[i] = operationRow[k];
        //   k++;
        // }else if(k >= 4 && !flag){
        //   operatnRow[i] = operationRow[k++];
        //   // if(k === 4){
        //   //   operatnRow[i] = operationRow[k++];
        //   // }else{
        //   //   operatnRow[i] = operationRow[++k];
            
        //   // }
        // }
        console.log(k);
    }
    SetOperRow(operatnRow);
    // periodOfLoanf();
}


const getInputTableReady = () => {
    // if(isInputReady === true){
        setInputReady(true);
        SetFinalTableReady(false);
        console.log(isInputReady, isFinalTableReady);
}

const getInputForOs = () => {
    setGetInputFlag(!isgetInputFlag);
}

const getVal = () => {
    console.log("val:- ", val);
    console.log("SubTotalVal:- ", subTotalVal);
}

const addSalesItem = () => {
    const projectDetail = {
        productId: null,
        borrowerId: borrowerDetailProjectItem.BorrowerId,
        aboutSales: aboutSales,
        amountOfSales: parseFloat(amountOfSales),
        isActive: 0
    };

    // Update projects array
    setProjects(prevProjects => [...prevProjects, projectDetail]);

    // Update total cost of the project
    setTotalCostOfProject(prevTotalCost => parseFloat( prevTotalCost ) + parseFloat(amountOfSales));

    // Reset aboutProject and amountOfProject
    setAboutSales('');
    setAmountOfSales(0.0);
    console.log(projects);

    // SetValVarIndex(projects.length);

    
    let updatedSubTotalVal = [...subTotalVal];
    updatedSubTotalVal[1] = parseFloat(totalCostOfProject);
    console.log(updatedSubTotalVal);
    setSubTotalVal(updatedSubTotalVal);
};

function closeMessageModal(){
  SetIsShowSelectBorrowerMessage(false);
}
const hideButtons = () => {
  SetIsShowButton(!buttonFlag);
  SetButtonFlag(!buttonFlag);
}

    // periodOfLoanf();

    return(
        <>
        {/* <button onClick={inintValArr}>Initialize_2-D_Array</button> */}
        {IsShowButton &&
          <>
        <button onClick={getRepaymentScheduleDetails}>1. GetDetailInterest</button>
        <button onClick={getDepreciationAllowable}>2. GetDepreciationAllowable</button>
        {isInputForOs &&
        <button onClick={getInputForOs}>3. InputForOs</button>}
        
        {(isgetInputFlag) && (<div>
          <button onClick={getInputTableReady}>4. GetInputTableReady</button>
          <button onClick={refresh}>5. Refresh</button>
          <span>&nbsp;6. Rate Of Increment:-&nbsp;</span>__&nbsp;Rev:
            <select value={revenuePercent} onChange={handleRevenueChangePercent}>
            {rateOfIncrement.map((element, index) => (
                <option key={index} value={element}>{element}</option>
            ))}
        </select>__&nbsp;Exp:
        <select value={expensePercent} onChange={handleExpenseChangePercent}>
            {rateOfIncrement.map((element, index) => (
                <option key={index} value={element}>{element}</option>
            ))}
        </select>
        <button onClick={getVal}>7. GetValAndSubTotalVal</button>
        <button onClick={createValVar}>8. CreateVal</button>
        <button onClick={getFinalTableReady}>9. GetFinalTableReady</button>
        <button onClick={periodOfLoanf}>10. OperatingStatement</button>
        <button onClick={saveStatementDetails}>11. SaveOperatingStatementDetails</button>
        <button onClick={generatePDF}>12. DownloadAsPdf</button>
        </div>)}</>}
        <button onClick={hideButtons}>...</button>
        <div ref={contentRef}>
            <br/>
            <br/>
            <h2 style={{textAlign:"center"}}>{borrowerProfile.borrower_sFirmName}</h2>
            <h3 style={{textAlign:"center"}}>Borrower:&nbsp; {borrowerProfile.borrowerName}</h3>
            <h3 style={{textAlign:"center"}}>Address of unit:  {borrowerProfile.borrowerRegisteredAddress}</h3>
            <h4 style={{textAlign:"center"}}><u>Operating Statement (FORM-1)</u></h4>
            <br/> 
        {((isFinalTableReady) && (
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr style={{ border: '1px solid black' }}>
              {projectedAmount.map((element, index) => (
                <th key={index} style={{ border: '1px solid black' }}>
                  {element}
                </th>
              ))}
            </tr>
          </thead>
          <tbody style={{ border: 'collapse', width: '100%'}}>
            {/* {operRow.map((element, index) => (
              <tr key={index}>
                <th style={{ border: '1px solid black' }}>{element}</th>
                {ValVar.map((value, indexing) => (
                  <tr key={indexing} style={{ border: '1px solid black' }}>
                    <th>{value}</th> </tr>
                ))}
                
                
              </tr>
            ))}*/ }
            {/* {newColumns.map((columns, index) => <td key={index}>
              {tableData.map((element, _index) => <th>
                {
                  element[columns]
                }
                </th>)}
            </td>)} */}
            {

              tableData.map((element, index) => <tr key={index}>
                {
                  newColumns.map((columns, indice) => <th style={{ width: "100px"}}>
                    {
                      element[columns]
                    }
                  </th>)
                }
              </tr>)
            }

            {/* {
              tableData.map((element, index) => <tr key={index}>
               
                <th>
                  {
                    element.Particulars
                  }
                </th>
                <th>
                  {
                    element["Estimated Amount Rs"]
                  }
                </th>
                <th>
                  {
                    element["Projected Amount Rs"]
                  }
                </th>
              </tr>)} */}
          </tbody>
        </table>
      ))}
      <h5 style={{textAlign:"right"}}>FOR {borrowerProfile.borrower_sFirmName}</h5>
            <h5 style={{textAlign:"right"}}>{borrowerProfile.borrowerName} (Borrower)</h5>
            {/* <h5 style={{textAlign:"right"}}>Borrower</h5> */}
        </div>
        {/* <button onClick={valVarIndexSet}>12. SetValVarIndex</button> */}

        {/* <select value={rateofDepriciation[index]} onChange={handlePrint}>
        {NoOfYearsUsed.map((years, index) => (
            <option key={index} value={years}>{years}</option>
            ))}

            </select> */}
            &nbsp;
            {/* <DataTable>
              columns={columns}
              data={tableData}
            </DataTable> */}
            {/* <table>
              <thead>
                 <tr >{columns.map((element, index) => (<th key={index} style={{ border: '1px solid black'}}>
              {element.name}
            </th>))}</tr>
            </thead>
            <tbody></tbody>
            </table> */}
            
        {((isInputReady) && (
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
                <tr style={{ border: '1px solid black'}}>
                    {projectedAmount.map((element, index) => (<th key={index} style={{ border: '1px solid black'}}>{element}</th>))}
                </tr>
            </thead>
            <tbody>
                {/* {
                   periods.map((_element,index) => (<td key={index}> */}
                    {operationRow.map((element, index) => (
                        <tr key={index}>
                            <th style={{ border: '1px solid black', width: "100px", backgroundColor: 'greenyellow'}}>{element} </th>
                            {
                                (index === 35) ? <td style={{width: '300px', height: '20px', backgroundColor: 'orange'}}>{subTotalVal[11]-subTotalVal[12]}</td>:
                                (index === 31) || (index === 32) || (index === 33) || (index === 34) ? <td style={{width: '300px', height: '20px', backgroundColor: 'yellow'}}><input
                                style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px', backgroundColor: 'redyellow' }}
                                type="number" 
            value={val[index]} // Bind the input value to the state
            onChange={(e) => handleVal23Change(e, index)} // Handle input changes
          /></td>:
                                (index === 30) ? <td></td>:
                                (index === 29) ? <td style={{width: '300px', height: '20px', backgroundColor: 'orange'}}>{subTotalVal[11]}</td>:
                                (index === 28) ? <td style={{width: '300px', height: '20px', backgroundColor: 'orange'}}>{subTotalVal[10]}</td>:
                                (index === 27) ? <td style={{width: '300px', height: '20px', backgroundColor: 'yellow'}}><input
                                style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px', backgroundColor: 'redyellow' }}
                                type="number"
            value={val[index]} // Bind the input value to the state
            onChange={(e) => handleVal21Change(e, index)} // Handle input changes
          /></td>:
                                (index === 26) ? <td style={{width: '300px', height: '20px', backgroundColor: 'orange'}}>{subTotalVal[9]}</td>:
                                (index === 25) ? <td style={{width: '300px', height: '20px', backgroundColor: 'orange'}}>{subTotalVal[8]}</td>:
                                (index === 23) || (index === 24) ? <td style={{width: '300px', height: '20px', backgroundColor: 'yellow'}}><input
                                style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px', backgroundColor: 'redyellow' }}
                                type="number" 
            value={val[index]} // Bind the input value to the state
            onChange={(e) => handleVal19Change(e, index)} // Handle input changes
          /></td>:
                                (index === 22) ? <td style={{width: '300px', height: '20px', backgroundColor: 'white'}}></td>:
                                (index === 21) ? <td style={{width: '300px', height: '20px', backgroundColor: 'orange'}}>{subTotalVal[7]}</td>:                                
                                (index === 20) ? <td style={{width: '300px', height: '20px', backgroundColor: 'yellow'}}>{depreciationAllowable[0]}</td>:
                                (index === 19) ? <td style={{width: '300px', height: '20px', backgroundColor: 'yellow'}}>{periodAndMonthArr[0].Interest.toFixed(2)}</td>:
                                (index === 18) ? <td><input
                                style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px', backgroundColor: 'redyellow' }}
                                type="number" 
            value={val[index]} // Bind the input value to the state
            onChange={(e) => handleVal17Change(e, index)} // Handle input changes
          /></td>:
                                (index === 17) ? <td></td> :
                                (index === 16) ? <td>{subTotalVal[6]}</td>:
                                (index === 14 || index === 15) ? <td><input
                                style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px', backgroundColor: 'redyellow' }}
                                type="number" 
            value={val[index]} // Bind the input value to the state
            onChange={(e) => handleVal15Change(e, index)} // Handle input changes
          /></td>:
                                (index === 13) ? <td></td>:
                                (index === 12) ? <td style={{width: '300px', height: '20px', backgroundColor: 'orange'}}>{subTotalVal[5]}</td>:  
                                (index === 11)
                                ? <td style={{width: '300px', height: '20px', backgroundColor: 'yellow'}}><input
                                style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px', backgroundColor: 'redyellow' }}
                                type="number" 
            value={val[index]} // Bind the input value to the state
            onChange={(e) => handleVal11Change(e, index)} // Handle input changes
          /></td>:
                            (index === 10) ? <td style={{width: '300px', height: '20px', backgroundColor: 'orange'}}>{subTotalVal[4]}</td>:  
                            (index === 9)
                            ? <td style={{width: '300px', height: '20px', backgroundColor: 'yellow'}}><input
                            style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px', backgroundColor: 'redyellow' }}
                            type="number"
        value={val[index]} // Bind the input value to the state
        onChange={(e) => handleVal9Change(e, index)} // Handle input changes
      /></td>:
                            
                            (index === 8) 
                            ? <td style={{width: '300px', height: '20px', backgroundColor: 'orange'}}>{subTotalVal[3]}</td>:
                                (index === 7) ? <td style={{width: '300px', height: '20px', backgroundColor: 'yellow'}}>
                                <input
                        style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px', backgroundColor: 'redyellow' }}
                        type="number" 
    value={val[index]} // Bind the input value to the state
    onChange={(e) => handleVal7Change(e, index)} // Handle input changes
  /></td>:
                                (index === 6) ? <td style={{width: '300px', height: '20px', backgroundColor: 'orange'}}>{subTotalVal[2]}</td>:
                                (index === 5) ? <td style={{width: '300px', height: '20px', backgroundColor: 'yellow'}}>
                                    <input
                            style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px', backgroundColor: 'redyellow' }}
                            type="number" 
        value={val[index]} // Bind the input value to the state
        onChange={(e) => handleVal5Change(e, index)} // Handle input changes
      />
                                </td>:
                                (index === 4) ? <td style={{width: '300px', height: '20px', backgroundColor: 'orange'}}>{subTotalVal[1]}</td> :
                                (index === 3) ? 
                                <td>
                                  <span>Expenses/Cost:- </span>
                    <input
                      style={{
                        width: '300px',
                        height: '20px',
                        borderRadius: '5px',
                        padding: '2px',
                        margin: '5px',
                        backgroundColor: 'yellow'
                      }}
                      value={aboutSales}
                      onChange={(e) => setAboutSales(e.target.value)}
                    />
                    <span>Amount Rs.</span>
                    <input
                      style={{
                        width: '150px',
                        height: '20px',
                        borderRadius: '5px',
                        padding: '2px',
                        margin: '5px',
                        backgroundColor: 'yellow'
                      }}
                      value={amountOfSales}
                      type='number'
                      onChange={(e) => setAmountOfSales(parseFloat(e.target.value))}
                    />
                    <button
                      style={{
                        backgroundColor: 'blue',
                        width: '60px',
                        height: '40px',
                        color: 'white',
                        borderRadius: '5px'
                      }}
                      onClick={addSalesItem}
                    >
                      Add Item
                    </button>
                    <table>
                      <thead>
                        <tr>
                          <th>Expenses/Cost</th>
                          <th>Amount (Rs.)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projects.map((project, index) => (
                          <tr key={index}>
                            <td>{project.aboutSales}</td>
                            <td>{project.amountOfSales}</td>
                          </tr>
                        ))}
                        <tr key='totalCost'>
                          <td><b>Total Expenses</b></td>
                          <td>{totalCostOfProject}</td>
                        </tr>
                      </tbody>
                    </table>
                              </td>
                            :
                            (index!==2)?
                            <td style={{width: '300px', height: '20px', backgroundColor: 'yellow'}}><input
                            style={{ width: '150px', height: '20px', borderRadius: '5px', padding: '2px', margin: '5px', backgroundColor: 'redyellow' }}
                            type="number" 
        value={val[index]} // Bind the input value to the state
        onChange={(e) => handleValChange(e, index)} // Handle input changes
      /></td>: (<td style={{width: '300px', height: '20px', backgroundColor: 'orange'}}>{subTotalVal[0]}</td>)}
                        </tr>
                    ))
                    }
                    {/* </td> */}
                    {/* )) */}
                {/* } */}
            {/* <tr style={{ border: '1px solid black'}}>

                    
                    <th style={{width:"30px"}}>{operationRow[0]}</th>
                </tr>
                <tr style={{ border: '1px solid black'}}>
               
                <div>{operationRow[1]}</div>
                
                <div>{operationRow[2]}</div>

                </tr>
                <div style={{border: '1px solid black', width: "30px"}}>
                    {operationRow[3]}
                </div> */}
                

            </tbody>
        </table>))}     

        {isShowSelectBorrowerMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeMessageModal}>&times;</span>
                        
                            <h2 style={{ color: "green" }}>
                                Please select a borrower!..
                            </h2>

                            <center><button onClick={closeMessageModal} style={{backgroundColor: 'green', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}

        {isShowDenialMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeDenialMessageModal}>&times;</span>
                        
                            <h2 style={{ color: "red" }}>
                            Project Data is Missing!..
                            </h2>

                            <center><button onClick={closeDenialMessageModal} style={{backgroundColor: 'red', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}

{isShowNoDataMessage && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeNoDataModal}>&times;</span>
                        
                            <h2 style={{ color: "red" }}>
                                Data Not Found!..
                            </h2>

                            <center><button onClick={closeNoDataModal} style={{backgroundColor: 'red', border: '1px solid black', fontStyle: 'italic', borderRadius: '5px', color: 'ButtonFace'}}>Okay</button></center>
                    </div>
                </div>
            )}

        </>
    );
  }

export default OperatingStatement;